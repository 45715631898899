import React, { Component } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectToken, selectTrains } from '../../../../store/selectors';
import { setTrains,setTrainId } from '../../../../store/actions';
import { bindActionCreators } from 'redux';
import Axios from 'axios';

let order = 0
class TrainList extends Component {

    state = {
        "trains": [],
    }

    componentDidMount = async () => {
        order = 0
        await Axios.get("https://tmstechnic.pythonanywhere.com/loko/train/list?ordering=TrainName", {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(res => {
                this.props.setTrains(res.data)
                this.setState({
                    trains: res.data
                })
            })
        $(document).ready(function () {

            $('#example1').DataTable({
                "language": {
                    "lengthMenu": "Sayfada _MENU_ Kayıt Göster",
                    "zeroRecords": "Aradığınızla eşleşen bir kayıt bulunamadı",
                    "info": "_TOTAL_ Kayıttan _START_ ile _END_ Arası Kayıtlar",
                    "infoEmpty": "Hiç Kayıt Bulunamadı",
                    "infoFiltered": "( _MAX_ Kayıt Arasından )",
                    "search": "Bul:",
                },
                // "order": [[3, "desc"]]
            });
        });
    }

    onClickDelete = async (id) => {
        await Axios.delete(`https://tmstechnic.pythonanywhere.com/loko/train/delete/${id}`, {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(async () => {
                window.location.reload()
            })
    }

    onClickEdit = async(id) => {
        this.props.setTrainId(id)
        window.location.replace("/twenty-trains/edit/"+id)
    }

    render() {
        return (
            <div>
                <div className="page-name-part">

                    <Link to="/bilgilerim" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>

                    <h2>
                        Tren Listesi
                    </h2>

                </div>
                <div className="back-body border-0 text-left ">
                    <Link to="/twenty-trains/add" className="btn success-button">Tren Ekle</Link>
                </div>
                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            Tren Listesi
                        </div>
                    </div>
                    <div className="padding-div-back">
                        <table id="example1" className="display">
                            <thead>
                                <tr>
                                    <th>Sıra</th>
                                    <th>Tren Numarası</th>
                                    <th>Durumu</th>
                                    <th>İşlemler</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.trains.map(train => {
                                        order += 1
                                        return (
                                            <tr>
                                                <td>{order}</td>
                                                <td>{train.TrainName}</td>
                                                <td>{train.Status}</td>
                                                <td>
                                                    <div>
                                                        <button className="btn btn-sm edit-button" onClick={this.onClickEdit.bind(this,train.GuidId)}>
                                                            Düzenle
                                                        </button>
                                                        <button className="btn btn-sm delete-button" onClick={this.onClickDelete.bind(this, train.GuidId)}>
                                                            Sil
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token: selectToken(),
    trains: selectTrains(),
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTrains,setTrainId
        }, dispatch)
    )
)

export default connect(mapStateToProps, mapDispatchToProps)(TrainList)