import Axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectToken, selectTrains } from '../../../../store/selectors'
import { Link } from 'react-router-dom'
let ProfilImage = false;

class TwentySixMonthAdd extends Component {
    state = {
        "TrainName": "",
        "TrainId": null,
        "Status": "",
        "LastMaintenance": "",
        "NextMaintenance": "",
        "Doppler": "",
        "FirstAKSRight": "",
        "FirstAKSLeft": "",
        "SecondAKSRight": "",
        "SecondAKSLeft": "",
        "ThirdAKSRight": "",
        "ThirdAKSLeft": "",
        "FourthAKSRight": "",
        "FourthAKSLeft": "",
        "FifthAKSRight": "",
        "FifthAKSLeft": "",
        "SixthAKSRight": "",
        "SixthAKSLeft": "",
        "Content": "",
        "Image": null,
    }
    onChangeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeInputTrain = async (e) => {
        if (e.target.value === "") {
            await this.setState({
                TrainId: e.target.value,
            })
        }
        else {
            let trainName = this.props.trains.filter(train => train.GuidId.toString() === e.target.value)
            await this.setState({
                TrainId: e.target.value,
                TrainName: trainName[0].TrainName,
                Status: trainName[0].Status
            })
        }
    }

    onChangeHandler = async (e) => {
        await this.setState({
            Image: e.target.files[0]
        })
        ProfilImage = true
    }

    onClickSave = async (e) => {
        e.preventDefault();
         let form_data = new FormData();
         if (this.state.TrainId === null||this.state.LastMaintenance===""||this.state.NextMaintenance==="") {
            alert("Lütfen Bilgileri Eksiksiz Giriniz")
        }
        else {
            ProfilImage ? form_data.append('Image', this.state.Image, this.state.Image.name) : <div></div>;
            form_data.append('TrainName', this.state.TrainName);
            form_data.append('TrainId', this.state.TrainId);
            form_data.append('Status', this.state.Status);
            form_data.append('LastMaintenance', this.state.LastMaintenance);
            form_data.append('NextMaintenance', this.state.NextMaintenance);
            form_data.append('Doppler', this.state.Doppler);
            form_data.append('FirstAKSRight', this.state.FirstAKSRight);
            form_data.append('FirstAKSLeft', this.state.FirstAKSLeft);
            form_data.append('SecondAKSRight', this.state.SecondAKSRight);
            form_data.append('SecondAKSLeft', this.state.SecondAKSLeft);
            form_data.append('ThirdAKSRight', this.state.ThirdAKSRight);
            form_data.append('ThirdAKSLeft', this.state.ThirdAKSLeft);
            form_data.append('FourthAKSRight', this.state.FourthAKSRight);
            form_data.append('FourthAKSLeft', this.state.FourthAKSLeft);
            form_data.append('FifthAKSRight', this.state.FifthAKSRight);
            form_data.append('FifthAKSLeft', this.state.FifthAKSLeft);
            form_data.append('SixthAKSRight', this.state.SixthAKSRight);
            form_data.append('SixthAKSLeft', this.state.SixthAKSLeft);
            form_data.append('Content', this.state.Content);
            await Axios.post("https://tmstechnic.pythonanywhere.com/loko/twentysixmonth/create/", form_data, {
                headers: {
                    Authorization: `JWT ${this.props.token}`
                }
            })
                .then(async () => {
                    await Axios.post("https://tmstechnic.pythonanywhere.com/loko/twentysixmonthalldatas/create/", {
                        "LastMaintenance":this.state.LastMaintenance,
                        "NextMaintenance":this.state.NextMaintenance,
                        "Doppler":this.state.Doppler,
                        "TrainId" :this.state.TrainId,   
                        "Status":this.state.Status,
                        "FirstAKSRight":this.state.FirstAKSRight,
                        "FirstAKSLeft":this.state.FirstAKSLeft,
                        "SecondAKSRight":this.state.SecondAKSRight,
                        "SecondAKSLeft":this.state.SecondAKSLeft,
                        "ThirdAKSRight":this.state.ThirdAKSRight,
                        "ThirdAKSLeft":this.state.ThirdAKSLeft,
                        "FourthAKSRight":this.state.FourthAKSRight,
                        "FourthAKSLeft":this.state.FourthAKSLeft,
                        "FifthAKSRight":this.state.FifthAKSRight,
                        "FifthAKSLeft":this.state.FifthAKSLeft,
                        "SixthAKSRight":this.state.SixthAKSRight,
                        "SixthAKSLeft":this.state.SixthAKSLeft,
                        "Content" : this.state.Content,
                    }, {
                        headers: {
                            Authorization: `JWT ${this.props.token}`
                        }
                    })
                        .then(() => {
                            this.setState({
                                TrainName: "",
                                TrainId: null,
                                GuidId: null,
                                Status: "",
                                LastMaintenance: "",
                                NextMaintenance: "",
                                Doppler: "",
                                FirstAKSRight: "",
                                FirstAKSLeft: "",
                                SecondAKSRight: "",
                                SecondAKSLeft: "",
                                ThirdAKSRight: "",
                                ThirdAKSLeft: "",
                                FourthAKSRight: "",
                                FourthAKSLeft: "",
                                FifthAKSRight: "",
                                FifthAKSLeft: "",
                                SixthAKSRight: "",
                                SixthAKSLeft: "",
                                Content: "",
                                Image: null,
                            })
                        })
                }
                )
                .catch(() => {
                    alert("Böyle bir tren verisi bulunmaktadır. ")
                })

        }

    }

    render() {
        return (
            <div>
                <div className="page-name-part">
                    <Link to="/twenty-sixmonth" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"/>
                    </Link>
                    <h2>
                        22000 6 Aylık Veri Ekle
                    </h2>
                </div>

                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            22000 6 Aylık Veri Ekle
                        </div>
                    </div>
                    <div className="padding-div-back">

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label">
                                Tren Numarası:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <select className="col-sm-12 form-select p-2" id="TrainId" name="TrainId"
                                    value={this.state.TrainId} onChange={this.onChangeInputTrain}>
                                    <option value="">Seçiniz</option>
                                    {
                                        this.props.trains.map(train => (
                                            <option value={train.GuidId}>{train.TrainName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                En Son Yapılan Bakım Tarihi:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="date" className="form-control pt-1" id="LastMaintenance" name="LastMaintenance"
                                    value={this.state.LastMaintenance} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Gelecek Bakım Tarihi:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="date" className="form-control pt-1" id="NextMaintenance" name="NextMaintenance"
                                    value={this.state.NextMaintenance} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                1.AKS Sağ Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="FirstAKSRight" name="FirstAKSRight"
                                    value={this.state.FirstAKSRight} onChange={this.onChangeInput} />
                            </div>

                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                1.AKS Sol Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="FirstAKSLeft" name="FirstAKSLeft"
                                    value={this.state.FirstAKSLeft} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                2.AKS Sağ Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="SecondAKSRight" name="SecondAKSRight"
                                    value={this.state.SecondAKSRight} onChange={this.onChangeInput} />
                            </div>

                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                2.AKS Sol Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="SecondAKSLeft" name="SecondAKSLeft"
                                    value={this.state.SecondAKSLeft} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">
                                3.AKS Sağ Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="ThirdAKSRight" name="ThirdAKSRight"
                                    value={this.state.ThirdAKSRight} onChange={this.onChangeInput} />
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                3.AKS Sol Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="ThirdAKSLeft" name="ThirdAKSLeft"
                                    value={this.state.ThirdAKSLeft} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                4.AKS Sağ Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="FourthAKSRight" name="FourthAKSRight"
                                    value={this.state.FourthAKSRight} onChange={this.onChangeInput} />
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                4.AKS Sol Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="FourthAKSLeft" name="FourthAKSLeft"
                                    value={this.state.FourthAKSLeft} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">
                                5.AKS Sağ Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="FifthAKSRight" name="FifthAKSRight"
                                    value={this.state.FifthAKSRight} onChange={this.onChangeInput} />
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                5.AKS Sol Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="FifthAKSLeft" name="FifthAKSLeft"
                                    value={this.state.FifthAKSLeft} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">
                                6.AKS Sağ Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="SixthAKSRight" name="SixthAKSRight"
                                    value={this.state.SixthAKSRight} onChange={this.onChangeInput} />
                            </div>
                            <label for="inputEmail3" className="col-sm-2 col-form-label">
                                6.AKS Sol Teker:
                            </label>
                            <div className="col-sm-1 mt-1">
                                <input type="text" className="form-control pt-1" id="SixthAKSLeft" name="SixthAKSLeft"
                                    value={this.state.SixthAKSLeft} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">
                                Doppler Kalibrasyonu
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="date" className="form-control pt-1" id="Doppler" name="Doppler"
                                    value={this.state.Doppler} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">
                                Açıklama:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <textarea className="form-control pt-1" id="Content" name="Content"
                                    value={this.state.Content} onChange={this.onChangeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail1" className="col-sm-2 col-form-label  ">
                                Resim Seçiniz:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="file" className="form-control pt-1" id="ProfilImage"
                                    accept="image/png, image/jpeg" onChange={this.onChangeHandler} />
                            </div>
                        </div>

                        <div className=" row text-right mt-4">
                            <div className="col-sm-8">
                                <button className="btn right-part-button" onClick={this.onClickSave}>
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token: selectToken(),
    trains: selectTrains(),
})

export default connect(mapStateToProps)(TwentySixMonthAdd)
