import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectToken, selectUser } from '../../store/selectors'
import { setUser } from '../../store/actions'
import axios from 'axios'
import { Link } from 'react-router-dom'

class ChangePassword extends Component {

    state = {
        "oldpassword": "",
        "newpassword": "",
        "newpasswordagain": "",
    }

    onChangeInput = e =>{
        this.setState({
            [e.target.name]:e.target.value.toLowerCase()
        })
    }

    onClickSave = async() => {
        if(this.state.newpassword === this.state.newpasswordagain){
            await axios.get(`https://tmstechnic.pythonanywhere.com/loko/users/detail/${this.props.user.GuidId}`,{
                headers:{
                    Authorization:`JWT ${this.props.token}`
                }
            })
            .then(async(res)=>{
               if(this.state.oldpassword === res.data.Password){
                    await axios.put(`https://tmstechnic.pythonanywhere.com/loko/users/update/${this.props.user.GuidId}`,{
                        'Password':this.state.newpassword,
                        'UserName':this.props.user.UserName,
                    },{
                        headers:{
                            Authorization:`JWT ${this.props.token}`
                        }
                    })
                    .then(res=>{
                        this.props.setUser(res.data)
                        alert("Şifreniz başarıyla değiştirilmiştir.")
                        window.location.replace("/bilgilerim")
                    })
               } 
        }).catch(()=>{
            alert("Başarısız")
        })
        }
        else{
            alert("Yeni şifreniz ile tekrarı uyuşmamaktadır.")
        }
    }

    render() {
        return (
            <div>
                <div className="page-name-part">
                    <Link to="/bilgilerim" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>
                
                    <h2>
                        Şifre Yenile
                    </h2>
                </div>

                <div className="back-body">

                    <div className="back-body-header">
                        <div className="w-75 link">
                            Şifre Yenile
                        </div>
                    </div>

                    <div className="padding-div-back">

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label ">
                                Eski Şifreniz:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="password" className="form-control" id="oldpassword" name="oldpassword" placeholder="Eski Şifrenizi Giriniz"
                                value={this.state.oldpassword} onChange={this.onChangeInput} maxLength="25" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label ">
                                Yeni Şifreniz:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="password" className="form-control" id="newpassword" name="newpassword" placeholder="Yeni Şifrenizi Giriniz"
                                 value={this.state.newpassword} onChange={this.onChangeInput} maxLength="25" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label ">
                                Yeni Şifreniz (Tekrar):
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="password" className="form-control" id="newpasswordagain" name="newpasswordagain" placeholder="Şifrenizi Tekrar Giriniz"
                                 value={this.state.newpasswordagain} onChange={this.onChangeInput} maxLength="25" />
                            </div>
                        </div>

                        <div className=" row text-right mt-4">
                            <div className="col-sm-8">
                                <button className="btn right-part-button" onClick={this.onClickSave}>
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = createStructuredSelector({
    user:selectUser(),
    token:selectToken(),
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setUser,
        },dispatch)
    )
)
export default connect(mapStateToProps,mapDispatchToProps)(ChangePassword)