import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStructuredSelector } from 'reselect'
import { selectTwentySixMonth } from '../../../../store/selectors'
const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        marginTop: "10px",
        marginBottom: "10px"
    },
    heading: {
        fontSize: "15px",
        flexShrink: 0,

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
function SixMonthPopup(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className="modal fade" id="openSixMonthly" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                    <div className="modal-header mx-auto modal-header-part">
                        <span className="modal-header-part-span">
                            <i className="fa fa-subway mr-1 ml-0" style={{ fontSize: "20px" }} />
                            6 Aylık Bakım 22000
                        </span>

                        <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                            ×
                        </button>
                    </div>
                    <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>
                        <div className="popup-main-div">
                            {/* <Date /> */}
                            <div className={classes.root}>
                                {
                                    props.twentysixmonth.map(tsm => (
                                        <Accordion expanded={expanded === tsm.TrainName} onChange={handleChange(tsm.TrainName)}  key={tsm.TrainName}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={tsm.TrainName}
                                                id={tsm.TrainName}
                                            >
                                                <Typography component={'span'} className={classes.heading}>
                                                    { tsm.TrainName }
                                                </Typography>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography component={'span'}>
                                                    <div className="p-3">
                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <b>6 Aylık Bakım Tarihi : </b>{tsm.LastMaintenance.split("-").reverse().join("-")}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <b>Sonra ki Bakım Tarihi :</b>  {tsm.NextMaintenance.split("-").reverse().join("-")}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <b>Durumu :</b> {tsm.Status}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col-5">
                                                                <b>Bakımda Ölçülen Teker Çapları :</b>
                                                            </div>
                                                            <div className="col-7">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr style={{ backgroundColor: "#ec7424", color: "white" }}>
                                                                            <th scope="col">AKS</th>
                                                                            <th scope="col">{ tsm.TrainName }</th>
                                                                            <th scope="col">{ tsm.TrainName }</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tb">
                                                                    <tr>
                                                                        <th scope="row">1</th>
                                                                        <td>{tsm.FirstAKSLeft}</td>
                                                                        <td>{tsm.FirstAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">2</th>
                                                                        <td>{tsm.SecondAKSLeft}</td>
                                                                        <td>{tsm.SecondAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">3</th>
                                                                        <td>{tsm.ThirdAKSLeft}</td>
                                                                        <td>{tsm.ThirdAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">4</th>
                                                                        <td>{tsm.FourthAKSLeft}</td>
                                                                        <td>{tsm.FourthAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">5</th>
                                                                        <td>{tsm.FifthAKSLeft}</td>
                                                                        <td>{tsm.FifthAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">6</th>
                                                                        <td>{tsm.SixthAKSLeft}</td>
                                                                        <td>{tsm.SixthAKSRight}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <b>Doppler Kalibrasyonu :</b>  {tsm.Doppler.split("-").reverse().join("-")}
                                                            </div>
                                                        </div>

                                                        <div className="row mb-2">
                                                            <div className="col-3">
                                                                <b> Açıklama :</b>
                                                            </div>
                                                            <div className="col-9" style={{
                                                                wordWrap: "break-word"
                                                            }}>
                                                                <p>
                                                                {tsm.Content}
                                                                </p>
                                                                <img src={tsm.Image} alt={tsm.Image} style={{ width: "50%", objectFit: "scale-down" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">
                            Kapat
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    twentysixmonth: selectTwentySixMonth(),
})
export default connect(mapStateToProps)(SixMonthPopup)