import Axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectToken, selectTrainId } from '../../../../store/selectors'
import { Link } from 'react-router-dom'

class UpdateTrain extends Component {
    state = {
        "TrainName": "",
        "Status": "",
        "GuidId": null
    }

    componentDidMount = async () => {
        await Axios.get(`https://tmstechnic.pythonanywhere.com/loko/train/detail/${this.props.trainId}`, {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(response => {
                this.setState({
                    TrainName: response.data.TrainName,
                    Status: response.data.Status,
                    GuidId: response.data.GuidId,
                })
            })
    }

    onChangeTrainName = (e) => {
        this.setState({
            TrainName: e.target.value
        })
    }

    onChangeStatus = (e) => {
        this.setState({
            Status: e.target.value
        })
    }

    onClickSave = async () => {
        await Axios.put(`https://tmstechnic.pythonanywhere.com/loko/train/update/${this.state.GuidId}`, this.state, {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(() => {

            })

        await Axios.put(`https://tmstechnic.pythonanywhere.com/loko/twentymonthly/update/trainId/${this.state.GuidId}`, {
            "TrainName": this.state.TrainName,
            "Status": this.state.Status,
        }, {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })

            .then(() => {
                window.location.replace("http://localhost:3000/twenty-trains")
            })

        await Axios.put(`https://tmstechnic.pythonanywhere.com/loko/twentysixmonth/update/trainId/${this.state.GuidId}`, {
            "TrainName": this.state.TrainName,
            "Status": this.state.Status,
        }, {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })

            .then(() => {
                window.location.replace("http://localhost:3000/twenty-trains")
            })

            .catch(error => {
                window.location.replace("http://localhost:3000/twenty-trains")
            })
    }

    render() {
        return (
            <div>
                <div className="page-name-part">
                    <Link to="/twenty-trains" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>
                    <h2>
                        Tren Güncelle
                    </h2>
                </div>

                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            Tren Güncelle
                        </div>
                    </div>
                    <div className="padding-div-back">

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label ">
                                Tren Adı:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control" id="TrainName" name="TrainName" value={this.state.TrainName} onChange={this.onChangeTrainName}
                                    maxLength="8" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="cinsiyet" className="col-sm-2 col-form-label  ">
                                Durumu:
                            </label>
                            <div className="col-sm-6" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="Authority"
                                    value={this.state.Status} onChange={this.onChangeStatus} defaultValue="Faal">
                                    <option value="Faal">Faal</option>
                                    <option value="GayriFaal">Gayri-Faal</option>
                                </select>
                            </div>
                        </div>

                        <div className=" row text-right mt-4">
                            <div className="col-sm-8">
                                <button className="btn right-part-button" onClick={this.onClickSave}>
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = createStructuredSelector({
    token: selectToken(),
    trainId: selectTrainId(),
})
export default connect(mapStateToProps)(UpdateTrain)