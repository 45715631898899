import * as constants from './constants'
export const setToken = token => ({type:constants.SET_TOKEN, payload:token})
export const setUser = user => ({type:constants.SET_USER, payload:user})
export const setDurum = state => ({type:constants.SET_STATE, payload:state})
export const setTwentyMonthly = twentymonth => ({type:constants.SET_TWENTYMONTHLY, payload:twentymonth})
export const setTwentySixMonth = twentysixmonth => ({type:constants.SET_TWENTYSIXMONTH, payload:twentysixmonth})
export const setTwentyTwoYears = twentytwoyears => ({type:constants.SET_TWENTYTWOYEARS, payload:twentytwoyears})
export const setGuidId = guidId => ({type:constants.SET_GUIDID, payload:guidId})
export const setTrains = trains => ({type:constants.SET_TRAINS, payload:trains})
export const addTrains = train => ({type:constants.ADD_TRAINS, payload:train})
export const setTrainId = Id => ({type:constants.SET_TRAINID, payload:Id})
export const setTwentyMonthlyId = id => ({type:constants.SET_TWENTYMID, payload:id})
export const setTwentySixMonthId = id => ({type:constants.SET_TWENTYSIXMID, payload:id})
export const setTwentyTwoYearsId = id => ({type:constants.SET_TWENTY_TWO_YEARS_MID, payload:id})
export const setTwentyMonthlyAllId = id => ({type:constants.SET_TWENTYMALLID, payload:id})
export const setTwentySixMonthAllId = id => ({type:constants.SET_TWENTYSIXALLID, payload:id})
export const setTwentyTwoYearsAllId = id => ({type:constants.SET_TWENTY_TWO_YEARS_ALL_ID, payload:id})
export const resetState = () => ({type:constants.RESET_STATE})