import React, { Component } from 'react'
import SixMonthsBody from './BodyPart/SixMonth/SixMonthsBody'
import SixMonthUpcoming from './BodyPart/SixMonth/SixMonthUpcoming'
import SixMonthPopup from './PopupPart/SixMonthPopup'

class TwentyTwoSixMonth extends Component {
    render() {
        return (
            <div className="number-parts">
                <div className="parts-header">
                    22000 Lokomotif 6 Aylık Bakımları
                </div>
 
                <SixMonthsBody/>
                
                <SixMonthUpcoming/>
                
                <SixMonthPopup/>

            </div>
        )
    }
}
export default TwentyTwoSixMonth