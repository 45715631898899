import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectToken,selectTwentySixMonthAllId } from '../../../../store/selectors'
import { Link } from 'react-router-dom'

function SixMonthALlDataDetail(props) {
    const [twentySixMonthAllId,settwentySixMonthAllId]=useState([])
    useEffect(async() => {
        await Axios.get(`https://tmstechnic.pythonanywhere.com/loko/twentysixmonthalldatas/detail/${props.twentySixMonthAllId}`,{
            headers: {
                Authorization: `JWT ${props.token}`
            }
        })
        .then(res => settwentySixMonthAllId(res.data))
    }, [])
    return (
        <div>
            <div className="page-name-part">
                <Link to="/twenty-sixmonth-alldatas" className="link">
                    <i className="fa fa-arrow-circle-o-left fa-2x"/>
                </Link>
                <h2>
                     Aylık Bakım Detay
                </h2>
            </div>

            <div className="back-body">
                <div className="back-body-header">
                    <div className="w-75 link">
                        Detay
                    </div>
                </div>
                <div className="padding-div-back">
                    <div className="p-3">
                        <div className="row mb-2">
                            <div className="col">
                                <b>Aylık Bakım Tarihi : </b>{twentySixMonthAllId.LastMaintenance}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Sonra ki Bakım Tarihi :</b>  {twentySixMonthAllId.NextMaintenance}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Durumu :</b> {twentySixMonthAllId.Status}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-3">
                                <b>Bakımda Ölçülen Teker Çapları :</b>
                            </div>
                            <div className="col-6">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ backgroundColor: "#ec7424", color: "white" }}>
                                            <th scope="col">AKS</th>
                                            <th scope="col">Sol Teker</th>
                                            <th scope="col">Sağ Teker</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tb">
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{twentySixMonthAllId.FirstAKSLeft}</td>
                                            <td>{twentySixMonthAllId.FirstAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>{twentySixMonthAllId.SecondAKSLeft}</td>
                                            <td>{twentySixMonthAllId.SecondAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>{twentySixMonthAllId.ThirdAKSLeft}</td>
                                            <td>{twentySixMonthAllId.ThirdAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>{twentySixMonthAllId.FourthAKSLeft}</td>
                                            <td>{twentySixMonthAllId.FourthAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>{twentySixMonthAllId.FifthAKSLeft}</td>
                                            <td>{twentySixMonthAllId.FifthAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">6</th>
                                            <td>{twentySixMonthAllId.SixthAKSLeft}</td>
                                            <td>{twentySixMonthAllId.SixthAKSRight}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Doppler Kalibrasyonu :</b>  {twentySixMonthAllId.Doppler}
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-3 pr-0">
                                <b> Açıklama :</b>
                            </div>
                            <div className="col-9" style={{
                                wordWrap: "break-word"
                            }}>
                                <p>
                                {twentySixMonthAllId.Content}
                                </p>
                                <img src={twentySixMonthAllId.Image} alt={twentySixMonthAllId.Image} style={{ width: "50%", objectFit: "scale-down" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentySixMonthAllId:selectTwentySixMonthAllId(),
    token:selectToken(),
})

export default connect(mapStateToProps)(SixMonthALlDataDetail)