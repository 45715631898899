import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
// import sagas from './store/sagas';
// import createSagaMiddleware from 'redux-saga';
import { PersistGate } from 'redux-persist/integration/react';
import { createStore } from 'redux'; // saga kullanılacaksa compose ve applyMiddleware import edilmeli
import reducers from './store/reducers'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // web için varsayılan localstorage


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['twentymonthly','twentysixmonth', 'twentytwoyears', 'user','guidId','trains','trainId','twentymonthlyid','twentysixmonthid', 'twentytwoyearsid', 'durum','twentymonthlyallid','twentysixmonthallid', 'twentytwoyearsallid', 'token'],// Whitelist (Kaydetmek istediğiniz spesifik bir reducer değişkenini içine yazabilirsiniz)
  blacklist: [],// Blacklist (Kaydetmek istemediğiniz spesifik bir reducer değişkenini içine yazabilirsiniz)
};

// Middleware: persisted reducerımızı oluşturuyoruz.
const persistedReducer = persistReducer(persistConfig, reducers)

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  // composeEnhancer(
  //   applyMiddleware(sagaMiddleware),
  // ),
);

// Middleware: persist store oluşturuyoruz.
let persistor = persistStore(store);

// sagaMiddleware.run(sagas); // 

ReactDOM.render(

    <Provider store={store}>
      <PersistGate loading={<div>Loading</div>} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
