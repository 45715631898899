import {createSelector} from 'reselect';

const domain = (state) => state;

export const selectToken = () => createSelector(
    domain,
    substate => substate.token,
);

export const selectUser = () => createSelector(
    domain,
    substate => substate.user
);

export const selectTwentyMonthly = () => createSelector(
    domain,
    substate => substate.twentymonthly
);

export const selectTwentySixMonth = () => createSelector(
    domain,
    substate => substate.twentysixmonth
);

export const selectTwentyTwoYears = () => createSelector(
    domain,
    substate => substate.twentytwoyears
);

export const selectGuidId = () => createSelector(
    domain,
    substate => substate.guidId
);

export const selectTrains = () => createSelector(
    domain,
    substate => substate.trains
);

export const selectTrainId = () => createSelector(
    domain,
    substate => substate.trainId
);

export const selectTwentyMonthlyId = () => createSelector(
    domain,
    substate => substate.twentymonthlyid
);

export const selectTwentySixMonthId = () => createSelector(
    domain,
    substate => substate.twentysixmonthid
);

export const selectTwentyTwoYearsId = () => createSelector(
    domain,
    substate => substate.twentytwoyearsid
);

export const selectDurum = () => createSelector(
    domain,
    substate => substate.durum
);

export const selectTwentyMonthlyAllId = () => createSelector(
    domain,
    substate => substate.twentymonthlyallid
);

export const selectTwentySixMonthAllId = () => createSelector(
    domain,
    substate => substate.twentysixmonthallid
);

export const selectTwentyTwoYearsAllId = () => createSelector(
    domain,
    substate => substate.twentytwoyearsallid
);