import React, { Component } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectToken, selectUser } from '../../../store/selectors';
import { setGuidId } from '../../../store/actions';
import { bindActionCreators } from 'redux';
import Axios from 'axios';
class UsersList extends Component {
    state = {
        "users": [],
    }
    async componentDidMount() {

        await Axios.get("https://tmstechnic.pythonanywhere.com/loko/users/list",{
            headers:{
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(res => {
                this.setState({
                    users: res.data.filter(user => user.GuidId !== this.props.user.GuidId)
                })
            })
            
            .catch(error => {
                console.log(error)
            })

        $(document).ready(function () {

            $('#example1').DataTable({
                "language": {
                    "lengthMenu": "Sayfada _MENU_ Kayıt Göster",
                    "zeroRecords": "Aradığınızla eşleşen bir kayıt bulunamadı",
                    "info": "_TOTAL_ Kayıttan _START_ ile _END_ Arası Kayıtlar",
                    "infoEmpty": "Hiç Kayıt Bulunamadı",
                    "infoFiltered": "( _MAX_ Kayıt Arasından )",
                    "search": "Bul:",
                },
                "order": [[0, "desc"]]
            });
        });
    }

    onClickEdit = (Id) => {
        this.props.setGuidId(Id)
        window.location.replace("/user/edit/"+Id)
    }

    onClickDelete = async(Id) => {
        await Axios.delete(`https://tmstechnic.pythonanywhere.com/loko/users/delete/${Id}`,{
            headers:{
                Authorization:`JWT ${this.props.token}`
            }
        })
        .then(()=> {
            let newusers = this.state.users.filter(user => user.GuidId !== Id)
            this.setState({
                users:newusers
            })
        })
    }

    render() {
        return (
            <div>
                <div className="page-name-part">

                    <Link to="/bilgilerim" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>

                    <h2>
                        Kullanıcı Listesi
                    </h2>
                    
                </div>
                <div className="back-body border-0 text-left ">
                    <Link to="/user/add" className="btn success-button">
                        Kullanıcı Ekle
                    </Link>
                </div>
                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            Kullanıcı Listesi
                        </div>
                    </div>
                    <div className="padding-div-back">
                        <table id="example1" className="display">
                            <thead>
                                <tr>
                                    <th>Adı</th>
                                    <th>Soyadı</th>
                                    <th>Kullanıcı Adı</th>
                                    <th>İşlemler</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.users.map(user => (
                                        <tr>
                                            <td>{user.Name}</td>
                                            <td>{user.Surname}</td>
                                            <td>{user.UserName}</td>
                                            <td>
                                                <div>
                                                    <button className="btn btn-sm edit-button" onClick={this.onClickEdit.bind(this,user.GuidId)}>
                                                        Düzenle
                                                    </button>
                                                    
                                                    <button className="btn btn-sm delete-button" onClick={this.onClickDelete.bind(this,user.GuidId)}>
                                                        Sil
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = createStructuredSelector({
    token: selectToken(),
    user:selectUser(),
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setGuidId
        }, dispatch)
    )
)
export default connect(mapStateToProps, mapDispatchToProps)(UsersList)