import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectToken, selectTwentyMonthlyId } from '../../../../store/selectors'
import { Link } from 'react-router-dom'

function TwentyMDetail(props) {
    const [twentyMDetail,setTwentyMDetail]=useState([])
    useEffect(async() => {
        await Axios.get(`https://tmstechnic.pythonanywhere.com/loko/twentymonthly/detail/${props.twentyMonthlyId}`,{
            headers: {
                Authorization: `JWT ${props.token}`
            }
        })
        .then(res => setTwentyMDetail(res.data))
    }, [])
    return (
        <div>
            <div className="page-name-part">
                <Link to="/twenty-monthly" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                </Link>
                <h2>
                    {twentyMDetail.TrainName} - Aylık Bakım Detay
                </h2>
            </div>

            <div className="back-body">
                <div className="back-body-header">
                    <div className="w-75 link">
                        {twentyMDetail.TrainName} - Detay
                    </div>
                </div>
                <div className="padding-div-back">
                    <div className="p-3">
                        <div className="row mb-2">
                            <div className="col">
                                <b>Aylık Bakım Tarihi : </b>{twentyMDetail.LastMaintenance}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Sonra ki Bakım Tarihi :</b>  {twentyMDetail.NextMaintenance}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Durumu :</b> {twentyMDetail.Status}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-3">
                                <b>Bakımda Ölçülen Teker Çapları :</b>
                            </div>
                            <div className="col-6">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ backgroundColor: "#ec7424", color: "white" }}>
                                            <th scope="col">AKS</th>
                                            <th scope="col">Sol Teker</th>
                                            <th scope="col">Sağ Teker</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tb">
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{twentyMDetail.FirstAKSLeft}</td>
                                            <td>{twentyMDetail.FirstAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>{twentyMDetail.SecondAKSLeft}</td>
                                            <td>{twentyMDetail.SecondAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>{twentyMDetail.ThirdAKSLeft}</td>
                                            <td>{twentyMDetail.ThirdAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>{twentyMDetail.FourthAKSLeft}</td>
                                            <td>{twentyMDetail.FourthAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>{twentyMDetail.FifthAKSLeft}</td>
                                            <td>{twentyMDetail.FifthAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">6</th>
                                            <td>{twentyMDetail.SixthAKSLeft}</td>
                                            <td>{twentyMDetail.SixthAKSRight}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Doppler Kalibrasyonu :</b> {twentyMDetail.Doppler}
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-3 pr-0">
                                <b> Açıklama :</b>
                            </div>
                            <div className="col-9" style={{
                                wordWrap: "break-word"
                            }}>
                                <p>
                                {twentyMDetail.Content}
                                </p>
                                <img src={twentyMDetail.Image} alt={twentyMDetail.Image} style={{ width: "50%", objectFit: "scale-down" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentyMonthlyId:selectTwentyMonthlyId(),
    token:selectToken(),
})

export default connect(mapStateToProps)(TwentyMDetail)