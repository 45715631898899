import Axios from 'axios';
import React, { useState, useRef,useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { setToken, setUser, setDurum, resetState } from '../store/actions'
import TMS from './Images/tms.png'

function Login(props) {
  const [durum, setDurum] = useState(false)
  let userr;
  let userName = useRef()
  let password = useRef()
  let history = useHistory();
  let token;
  let LogIn = {
    username: "tmslokomotif",
    password: "05533859341.ali",
  };

  const options = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(LogIn),
  };

  useEffect(() => {
    props.resetState()
  }, [])

  const onClickLogin = async () => {
    await fetch("https://tmstechnic.pythonanywhere.com/token-auth/", options)
    .then((r) => r.json())
    .then((data) => {
      if (
        data.non_field_errors === "Unable to log in with provided credentials."
      ) {

      } else {
        token = data.token
      }
    })
    .catch(() => {
      
    })

    await Axios.get("https://tmstechnic.pythonanywhere.com/loko/users/list", {
      headers: {
        Authorization: `JWT ${token}`,
      }
    })
      .then(response => {
        userr = response.data.find(auser => auser.UserName === userName.current.value.toLowerCase())

        if (userr !== undefined) {
          if (userName.current.value.toLowerCase() === userr.UserName && userr.Password === password.current.value.toLowerCase()) {
            props.setUser(userr)
            props.setDurum(true)
            props.setToken(token)
            
            history.push("/")
          }
          else {
            setDurum(true)
          }
        }

        else {
          setDurum(true)
        }
      })
      .catch(() => {
        window.location.reload()
      })

  }

  return (
    <div style={{ background: "#0d3b6c" }}>
      <div className="logindiv">
        <div style={{ height: "15%" }}>
          <img src={TMS} alt="loginimg" />
        </div>
        <form>
          <input type="text" ref={userName} placeholder="Kullanıcı adı" />
          <br /><br />
          <input type="password" ref={password} placeholder="Şifre" />
          <br /><br />
          <input className="btn btn-send-password" value="Giriş" onClick={onClickLogin} />
          <Link className="login-link" to="/passwordremember">
            Parolamı Unuttum
          </Link>
          {durum ? <div className="alert alert-danger">Girdiğiniz bilgileri kontrol ediniz</div> :
            <div></div>
          }

        </form>
        <p>
          TMS LOKOMOTİF BAKIM
        </p>
      </div >
    </div>
  )
}



const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setToken, setUser, setDurum, resetState
    }, dispatch)
  )
);
export default connect(null, mapDispatchToProps)(Login);