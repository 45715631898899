import Axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectToken } from '../../../store/selectors';
import { Link } from 'react-router-dom'

let ProfilImage = false;
class AddUser extends Component {
    state = {
        "Name": "",
        "Surname": "",
        "Authority": "",
        "Email": "",
        "Phone": "",
        "Address": "",
        "Image": "",
        "UserName": "",
        "PersonelNo": null,
        "Password": "",
    }

    handleChangeImage = (e) => {
        this.setState({
            Image:e.target.files[0]
        })
        ProfilImage = true
    }

    changeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeAuthority = (e) => {
        this.setState({
            Authority:e.target.value
        })
    }

    onClickSave = async(e) => {
        e.preventDefault();
        if(this.state.UserName ==="" || this.state.Password==="" || this.state.Authority==="" || this.state.Email ===""){
            alert("Lütfen Kullanıcı Adı, Yetki, Email ve Şifre kısımlarını boş geçmeyiniz.")
        }
        else{
            let form_data = new FormData();
            ProfilImage ? form_data.append('Image', this.state.Image, this.state.Image.name):<div></div>;
            this.state.PersonelNo !==null ? form_data.append('PersonelNo', Number(this.state.PersonelNo)):<div></div>
            form_data.append('UserName', this.state.UserName)
            this.state.Name !=="" ? form_data.append('Name', this.state.Name):<div></div>
            this.state.Surname !=="" ? form_data.append('Surname', this.state.Surname):<div></div>
            form_data.append('Authority', this.state.Authority)
            this.state.Email !=="" ? form_data.append('Email', this.state.Email):<div></div>
            this.state.Phone !=="" ? form_data.append('Phone', this.state.Phone):<div></div>
            this.state.PersonelNo !=="" ? form_data.append('Address', this.state.Address):<div></div>
            form_data.append('Password', this.state.Password.toLowerCase())
    
            await Axios.post("https://tmstechnic.pythonanywhere.com/loko/users/create",form_data,{
                headers:{
                    Authorization: `JWT ${this.props.token}`
                }
            })
            .then(() => {
                this.setState({
                    "Name": "",
                    "Surname": "",
                    "Authority": "",
                    "Email": "",
                    "Phone": "",
                    "Address": "",
                    "Image": null,
                    "UserName": "",
                    "PersonelNo": "",
                    "Password": "",
                })
            })
            .catch(()=>{
                alert("Böyle bir kullanıcı bulunmaktadır.")
            })
        }
    }

    render() {
        const {Name,Surname,Authority,UserName,PersonelNo,Email,Phone,Password,Address} = this.state
        return (
            <div>
                <div className="page-name-part">

                    <Link to="/users" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>

                    <h2>
                        Kullanıcı Ekle
                    </h2>

                </div>

                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            Kullanıcı Ekle
                        </div>
                    </div>
                    <div  className="padding-div-back">
                        <div className="form-group row">
                            <label for="inputEmail1" className="col-sm-2 col-form-label ">
                                Resim Seçiniz:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="file" className="form-control pt-1" id="ProfilImage"
                                    accept="image/png, image/jpeg" onChange={this.handleChangeImage} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label ">
                                Kullanıcı Adı:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control" id="UserName" onChange={this.changeInput} value={UserName} name="UserName" 
                                maxLength="15" placeholder="Kullanıcı Adını Giriniz*"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label " >
                                Şifre:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="password" className="form-control pt-1" id="Password" onChange={this.changeInput} value={Password} name="Password" 
                                maxLength="25" placeholder="Şifrenizi Giriniz*"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Personel No:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="PersonelNo" onChange={this.changeInput} value={PersonelNo} name="PersonelNo"
                                placeholder="Personel Numarasını Giriniz"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                İsim:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Name" onChange={this.changeInput} value={Name} name="Name"
                                maxLength="15" placeholder="İsminizi Giriniz"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Soyisim:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Surname" onChange={this.changeInput} value={Surname} name="Surname"
                                maxLength="20" placeholder="Soyadınızı Giriniz"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="cinsiyet" className="col-sm-2 col-form-label">
                                Yetki:
                            </label>
                            <div className="col-sm-6" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="Authority" onChange={this.onChangeAuthority} value={Authority}
                                    aria-label="Default select example">
                                    <option value="">Belirtilmemiş*</option>
                                    <option value="Manager">Süper Yönetici</option>
                                    <option value="User">Kullanıcı</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Email:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Email" onChange={this.changeInput} value={Email} name="Email"
                                placeholder="Email Adresinizi Giriniz"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Telefon:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Phone" onChange={this.changeInput} value={Phone} name="Phone" maxlength="11"
                                minLength="11" placeholder="Telefon Numaranızı başta sıfır olacak şekilde Giriniz"
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Adres:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Address" onChange={this.changeInput} value={Address} name="Address"
                                maxLength="100" placeholder="Adresinizi Giriniz"/>
                            </div>
                        </div>


                        <div className=" row text-right mt-4">
                            <div className="col-sm-8">
                                <button className="btn right-part-button" onClick={this.onClickSave}>
                                    Kaydet
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token:selectToken(),
})

export default connect(mapStateToProps)(AddUser)