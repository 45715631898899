import React, { Component } from 'react'
import OneMonthBody from './BodyPart/OneMonth/OneMonthBody';
import UpcomingMonthlyServices from './BodyPart/OneMonth/UpcomingMonthlyServices';
import OneMonthPopup from './PopupPart/OneMonthPopup';

class TwentyTwoMonthly extends Component {

    render() {
        return (
            <div className="number-parts">
                <div className="parts-header">
                    22000 Lokomotif Aylık Bakımları
                </div>

                <OneMonthBody/>

                <UpcomingMonthlyServices/>

                <OneMonthPopup/>
            
            </div>
        )
    }
}
export default TwentyTwoMonthly;