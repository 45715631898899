import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectToken, selectTwentyMonthlyAllId, selectTwentyMonthlyId } from '../../../../store/selectors'
import { Link } from 'react-router-dom'
function MonthlyAllDataDetail(props) {
    const [twentyMAllDetail,setTwentyMAllDetail]=useState([])
    useEffect(async() => {
        await Axios.get(`https://tmstechnic.pythonanywhere.com/loko/twentymonthlyalldatas/detail/${props.twentyMonthlyAllId}`,{
            headers: {
                Authorization: `JWT ${props.token}`
            }
        })
        .then(res => setTwentyMAllDetail(res.data))
    }, [])
    return (
        <div>
            <div className="page-name-part">
                <Link to="/twenty-monthly-alldatas" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                </Link>
                <h2>
                    Bakım Detay
                </h2>
            </div>

            <div className="back-body">
                <div className="back-body-header">
                    <div className="w-75 link">
                      Detay
                    </div>
                </div>
                <div className="padding-div-back">
                    <div className="p-3">
                        <div className="row mb-2">
                            <div className="col">
                                <b>Aylık Bakım Tarihi : </b>{twentyMAllDetail.LastMaintenance}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Sonra ki Bakım Tarihi :</b>  {twentyMAllDetail.NextMaintenance}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Durumu :</b> {twentyMAllDetail.Status}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-3">
                                <b>Bakımda Ölçülen Teker Çapları :</b>
                            </div>
                            <div className="col-6">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ backgroundColor: "#ec7424", color: "white" }}>
                                            <th scope="col">AKS</th>
                                            <th scope="col">Sol Teker</th>
                                            <th scope="col">Sağ Teker</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tb">
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{twentyMAllDetail.FirstAKSLeft}</td>
                                            <td>{twentyMAllDetail.FirstAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>{twentyMAllDetail.SecondAKSLeft}</td>
                                            <td>{twentyMAllDetail.SecondAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>{twentyMAllDetail.ThirdAKSLeft}</td>
                                            <td>{twentyMAllDetail.ThirdAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>{twentyMAllDetail.FourthAKSLeft}</td>
                                            <td>{twentyMAllDetail.FourthAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>{twentyMAllDetail.FifthAKSLeft}</td>
                                            <td>{twentyMAllDetail.FifthAKSRight}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">6</th>
                                            <td>{twentyMAllDetail.SixthAKSLeft}</td>
                                            <td>{twentyMAllDetail.SixthAKSRight}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <b>Doppler Kalibrasyonu :</b>  {twentyMAllDetail.Doppler}
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-3 pr-0">
                                <b> Açıklama :</b>
                            </div>
                            <div className="col-9" style={{
                                wordWrap: "break-word"
                            }}>
                                <p>
                                {twentyMAllDetail.Content}
                                </p>
                                <img src={twentyMAllDetail.Image} alt={twentyMAllDetail.Image} style={{ width: "50%", objectFit: "scale-down" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentyMonthlyAllId:selectTwentyMonthlyAllId(),
    token:selectToken(),
})

export default connect(mapStateToProps)(MonthlyAllDataDetail)