import React, { Component } from 'react'
import TwoYearsBody from './BodyPart/TwoYears/TwoYearsBody';
import TwoYearsUpcoming from './BodyPart/TwoYears/TwoYearsUpcoming';
import TwoYearsPopup from './PopupPart/TwoYearsPopup';

class TwoYearsMaintenance extends Component {

    render() {
        return (
            <div className="number-parts mr-0">
                <div className="parts-header">
                    22000 Lokomotif 2 Yıllık Bakımları
                </div>

                <TwoYearsBody/>

                <TwoYearsUpcoming/>

                <TwoYearsPopup/>
            
            </div>
        )
    }
}
export default TwoYearsMaintenance;