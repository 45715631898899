import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setTwentyMonthly, setTwentySixMonth, setTwentyTwoYears, setTrains } from '../../store/actions'
import Axios from 'axios'
import { createStructuredSelector } from 'reselect'
import { selectToken } from '../../store/selectors'
class Operations extends Component {

    componentDidMount = async () => {

        await Promise.all([
            Axios.get("https://tmstechnic.pythonanywhere.com/loko/twentymonthly/list?ordering=TrainName", {
                headers: {
                    Authorization: `JWT ${this.props.token}`,
                }
            })
                .then(response => {
                    this.props.setTwentyMonthly(response.data)
                })
                .catch(() => {
                    console.log("başarısız")
                }),

            Axios.get("https://tmstechnic.pythonanywhere.com/loko/twentysixmonth/list?ordering=TrainName", {
                headers: {
                    Authorization: `JWT ${this.props.token}`,
                }
            })
                .then(response => {
                    this.props.setTwentySixMonth(response.data)
                })
                .catch(() => {
                    console.log("başarısız")
                }),

            Axios.get("https://tmstechnic.pythonanywhere.com/loko/twoyears/list?ordering=TrainName", {
                headers: {
                    Authorization: `JWT ${this.props.token}`,
                }
            })
                .then(response => {
                    this.props.setTwentyTwoYears(response.data)
                })
                .catch(() => {
                    console.log("başarısız")
                }),

            Axios.get("https://tmstechnic.pythonanywhere.com/loko/train/list?ordering=TrainName", {
                headers: {
                    Authorization: `JWT ${this.props.token}`
                }
            })
                .then(res => {
                    this.props.setTrains(res.data)
                })
        ])

    }

    render() {
        return (
            null
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token: selectToken(),
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTwentyMonthly, setTwentySixMonth, setTwentyTwoYears, setTrains
        }, dispatch)
    )
)
export default connect(mapStateToProps, mapDispatchToProps)(Operations)