import React, { Component } from 'react'
import TCDD from '../Images/tcdd.png'
import TMS from '../Images/tms.png'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectUser } from '../../store/selectors'
class Navbar extends Component {
    render() {
        return (
            <div className="p-2 w-100 d-flex mb-3 navbar-hp">
                <div style={{ width: "30%"}}>
                    <img src={TMS} alt="tms" className="navbar-img" />
                </div>

                <div className="navbar-title">
                    <div >
                        TMS ETCS
                    </div>
                </div>

                <div className="navbar-text">

                    <Link to="/bilgilerim" className="link">
                        Merhaba, {this.props.user.Name}
                    </Link>

                    <Link to="/login" className="link" >
                        <i className="fas fa-sign-out-alt" />
                    </Link>

                    <img src={TCDD} alt="tcdd" />
                </div>
            </div>
        )
    }
}
const mapStateToProps = createStructuredSelector({
    user: selectUser(),
})
export default connect(mapStateToProps)(Navbar)