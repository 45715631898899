import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TMS from './Images/tms.png'

function PasswordRemember() {
    const [durum, setDurum] = useState(false)
    const [Username, setUsername] = useState("")
    
    let token;
    let LogIn = {
        username: "tmslokomotif",
        password: "05533859341.ali",
      };
    
      const options = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(LogIn),
      };

    const onChangeText = (e) => {
        setUsername(e.target.value.toLowerCase())
        setDurum(false)
    }

    const onClickSend = async () => {
        await fetch("https://tmstechnic.pythonanywhere.com/token-auth/", options)
            .then((r) => r.json())
            .then((data) => {
                if (
                    data.non_field_errors === "Unable to log in with provided credentials."
                ) {
                    alert(data.non_field_errors[0]);
                } else {
                    token = data.token
                }
            })
            .catch(() => {
                window.location.reload()
            })
        if (Username === "") {
            setDurum(true)
        }
        else {
            await axios.get(`https://tmstechnic.pythonanywhere.com/loko/users/detail/username/${Username}`, {
                headers: {
                    Authorization: `JWT ${token}`
                }
            })
                .then(res => {
                    window.Email.send({
                        Host: "mail.tmstechnic.com",
                        Username: "portal@tmstechnic.com",
                        Password: "Tms@554433!",
                        To: res.data.Email,
                        From: "portal@tmstechnic.com",
                        Subject: "Şifreniz",
                        Body: "Şifreniz: " + res.data.Password
                    }).then(() => {
                        alert("Emailinizi Kontrol Ediniz")
                        window.location.replace("/login")
                    }
                    );
                }).catch(() => {
                    alert("Böyle bir kullanıcı bulunmamaktadır.")
                })
        }

    }

    return (
        <div className="w-100" style={{ background: "#0d3b6c" }}>
            <div className="logindiv">
                <div style={{ height: "15%" }}>
                    <img src={TMS} alt="TMS" />
                </div>
                <form >
                    <input type="text" value={Username} onChange={onChangeText} placeholder="Kullanıcı adı" /><br /><br />
                    <input className="btn btn-send-password" value="Gönder" onClick={onClickSend} /> <br />
                    <Link className="login-link" to="/login">Giriş Yap</Link>
                    {durum ? <div className="alert alert-danger">Kullanıcı adı boş geçilemez.</div> :
                        <div></div>}
                </form>
                <p >TMS LOKOMOTİF BAKIM</p>
            </div >
        </div>
    )
}

export default PasswordRemember