import * as constants from './constants'

const initialState = {
    token: "",
    user: [],
    durum: false,
    twentymonthly: [],
    twentysixmonth: [],
    twentytwoyears: [],
    guidId: null,
    trains: [],
    trainId: null,
    twentymonthlyid: null,
    twentysixmonthid: null,
    twentytwoyearsid: null,
    twentymonthlyallid: null,
    twentysixmonthallid: null,
    twentytwoyearsallid: null,
}

export default function store(state = initialState, action) {
    const actions = action

    switch (actions.type) {
        case constants.SET_TOKEN: {
            return {
                ...state,
                token: action.payload
            }
        }
        case constants.SET_USER: {
            return {
                ...state,
                user: action.payload
            }
        }
        case constants.SET_TWENTYMONTHLY: {
            return {
                ...state,
                twentymonthly: action.payload
            }
        }
        case constants.SET_TWENTYSIXMONTH: {
            return {
                ...state,
                twentysixmonth: action.payload
            }
        }
        case constants.SET_TWENTYTWOYEARS: {
            return {
                ...state,
                twentytwoyears: action.payload
            }
        }
        case constants.SET_GUIDID: {
            return {
                ...state,
                guidId: action.payload
            }
        }
        case constants.SET_TRAINS: {
            return {
                ...state,
                trains: action.payload
            }
        }
        case constants.ADD_TRAINS: {
            return {
                ...state,
                trains: [...state.trains, action.payload]
            }
        }
        case constants.SET_TRAINID: {
            return {
                ...state,
                trainId: action.payload
            }
        }
        case constants.SET_TWENTYMID: {
            return {
                ...state,
                twentymonthlyid: action.payload
            }
        }
        case constants.SET_TWENTYSIXMID: {
            return {
                ...state,
                twentysixmonthid: action.payload
            }
        }
        case constants.SET_TWENTY_TWO_YEARS_MID: {
            return {
                ...state,
                twentytwoyearsid: action.payload
            }
        }
        case constants.SET_STATE: {
            return {
                ...state,
                durum: action.payload
            }
        }
        case constants.SET_TWENTYMALLID: {
            return {
                ...state,
                twentymonthlyallid: action.payload
            }
        }
        case constants.SET_TWENTYSIXALLID: {
            return {
                ...state,
                twentysixmonthallid: action.payload
            }
        }
        case constants.SET_TWENTY_TWO_YEARS_ALL_ID: {
            return {
                ...state,
                twentytwoyearsallid: action.payload
            }
        }
        case constants.RESET_STATE: {
            return {
                ...state,
                token: "",
                user: [],
                durum: false,
                twentymonthly: [],
                twentysixmonth: [],
                twentytwoyears: [],
                guidId: null,
                trains: [],
                trainId: null,
                twentymonthlyid: null,
                twentysixmonthid: null,
                twentytwoyearsid: null,
                twentytwoyearsallid: null,
            }
        }

        default: {
            return state;
        }
    }
}