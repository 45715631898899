import React, { Component } from 'react'
import { NavLink, Switch, Route } from 'react-router-dom'
import Bilgilerim from './Bilgilerim'
import AddUser from './Users/AddUser'
import UsersList from './Users/UsersList'
import UpdateUser from './Users/UpdateUser'
import TwentyMonthlyList from './TwentyMaintenance/Monthly/TwentyMonthlyList'
import TwentyMUpdate from './TwentyMaintenance/Monthly/TwentyMUpdate'
import TwentyMDetail from './TwentyMaintenance/Monthly/TwentyMDetail'
import TrainList from './TwentyMaintenance/Trains/TrainList'
import AddTrain from './TwentyMaintenance/Trains/AddTrain'
import UpdateTrain from './TwentyMaintenance/Trains/UpdateTrain'
import MonthlyAllDatas from './TwentyMaintenance/Monthly/Monthly-AllDatas'
import TwentyMonthlyAdd from './TwentyMaintenance/Monthly/TwentyMonthlyAdd'
import SixMonthAllDatas from './TwentyMaintenance/Six-Month/SixMonth-AllDatas'
import TwentySDetail from './TwentyMaintenance/Six-Month/TwentySDetail'
import TwentySixMonthList from './TwentyMaintenance/Six-Month/TwentySixMonthList'
import TwentySixMonthAdd from './TwentyMaintenance/Six-Month/TwentySixMonthAdd'
import TwentySUpdate from './TwentyMaintenance/Six-Month/TwentySUpdate'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectGuidId, selectTrainId, selectTwentyMonthlyAllId, selectTwentyMonthlyId, selectTwentySixMonthAllId, selectTwentySixMonthId, selectTwentyTwoYearsAllId, selectTwentyTwoYearsId, selectUser } from '../../store/selectors'
import ChangePassword from './ChangePassword'
import FooterBack from './FooterBack'
import MonthlyAllDataDetail from './TwentyMaintenance/Monthly/MonthlyAllDataDetail'
import SixMonthAllDataDetail from './TwentyMaintenance/Six-Month/SixMonthAllDataDetail'
import TwoYearsList from './TwentyMaintenance/TwoYears/TwoYearsList'
import TwoYearsAllDatas from './TwentyMaintenance/TwoYears/TwoYearsAllDatas'
import TwoYearsAdd from './TwentyMaintenance/TwoYears/TwoYearsAdd'
import TwoYearsDetail from './TwentyMaintenance/TwoYears/TwoYearsDetail'
import TwoYearsUpdate from './TwentyMaintenance/TwoYears/TwoYearsUpdate'
import TwoYearsAllDataDetail from './TwentyMaintenance/TwoYears/TwoYearsAllDataDetail'

class BackPage extends Component {

    state = {
        "durum": false
    };

    onClickButton = () => {
        this.setState({
            durum: !this.state.durum
        })
    };

    render() {
        return (
            <div className="wrapper bg-light">
                <nav id="sidebar" className={this.state.durum ? "active" : null}>
                    <div className="sidebar-header text-center">
                        {this.props.user.Image == null ? <i className="fa fa-user fa-6x w-50" style={{ color: "black" }} /> :
                            <img src={this.props.user.Image} alt={this.props.user.Image} style={{ width: "80%" }} />}

                    </div>
                    <div className="sidebar-header text-center">
                        <div className="navbar-name-part">
                            {this.props.user.Name} {this.props.user.Surname}
                        </div>
                        <div className="navbar-dropdown-part">
                            <div className="dropdown" >
                                <a href="#" className="dropdown-toggle text-muted text-center setting-bg bg-transparent" id="dropdownMenuButton" data-toggle="dropdown"
                                    style={{ background: "transparent" }}>
                                    <i className="fa fa-gear mr-1" style={{ fontSize: "13px" }} />
                                    <span style={{ fontSize: "13px" }}>
                                        Ayarlar
                                    </span>
                                </a>

                                <div className="dropdown-menu" style={{ marginLeft: "-15%" }} aria-labelledby="dropdownMenuButton">
                                    <NavLink className="dropdown-item changepassword" to="/ChangePassword" activeclassname="active">
                                        Şifre Değiştir
                                    </NavLink>
                                    <NavLink className="dropdown-item changepassword" to="/login" activeclassname="active">
                                        Çıkış Yap
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                    </div>

                    <ul className="list-unstyled components">
                        <li>
                            <NavLink to="/bilgilerim" activeclassname="active">
                                Bilgilerim
                            </NavLink>
                        </li>
                        {
                            this.props.user.Authority === "Manager" ?
                                <li>
                                    <NavLink to="/users" activeclassname="active">
                                        Kullanıcılar
                                    </NavLink>
                                </li> : null
                        }
                        {
                            this.props.user.Authority === "Manager" ? <li>
                                <a href="#pageSubmenuTwo" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                    22000
                                </a>
                                <ul className="collapse list-unstyled" id="pageSubmenuTwo">
                                    <li>
                                        <NavLink to="/twenty-trains" activeclassname="active">
                                            Trenler
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/twenty-monthly" activeclassname="active">
                                            Aylık
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/twenty-sixmonth" activeclassname="active">
                                            6 Aylık
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/two-years" activeclassname="active">
                                            2 Yıllık
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/twenty-monthly-alldatas" activeclassname="active">
                                            22000 Aylık Tüm Veriler
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/twenty-sixmonth-alldatas" activeclassname="active">
                                            22000 6 Aylık Tüm Veriler
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/two-years-alldatas" activeclassname="active">
                                            22000 2 Yıllık Tüm Veriler
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> : <li>
                                <a href="#pageSubmenuTwo" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">22000</a>
                                <ul className="collapse list-unstyled" id="pageSubmenuTwo">
                                    <li>
                                        <NavLink to="/twenty-monthly-alldatas" activeclassname="active">
                                            22000 Aylık Tüm Veriler
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/twenty-sixmonth-alldatas" activeclassname="active">
                                            22000 6 Aylık Tüm Veriler
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/two-years-alldatas" activeclassname="active">
                                            22000 2 Yıllık Tüm Veriler
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        }

                    </ul>
                </nav>

                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid row" style={{ paddingLeft: "1.5%" }}>
                            <div className="col-md-12 head-part">

                                <button type="button" className="open-close-button btn" onClick={this.onClickButton}>
                                    <i className="fas fa-align-left mr-1" />
                                    {
                                        this.state.durum ? <span className="menu">Menü Aç</span> : <span className="menu">
                                            Menü Kapat
                                        </span>
                                    }
                                </button>

                                {/* <div className="dropdown dropdown-responsive" style={{ marginLeft: "5px" }}>

                                    <button className="open-close-button-responsive btn">
                                        <i className="fas fa-align-left mr-1" />
                                    </button>

                                    <div className="dropdown-content" style={{ left: "0" }}>

                                        {this.props.user.Image == null ? <i className="fa fa-user fa-6x w-50 ml-3 mt-1" style={{ color: "black" }} /> :
                                            <img src={this.props.user.Image} alt={this.props.user.Image} style={{ width: "80%", marginLeft: "15px", marginTop: "5px" }} />}
                                        <NavLink to="/ChangePassword">
                                            Şifre Değiştir
                                        </NavLink>

                                        <NavLink to="/bilgilerim" >
                                            Bilgilerim
                                        </NavLink>

                                        {
                                            this.props.user.Authority === "Manager" ?

                                                <NavLink to="/users">
                                                    Kullanıcılar
                                                </NavLink>
                                                : null
                                        }

                                        {
                                            this.props.user.Authority === "Manager" ?
                                                <div className="dropdown dropright">
                                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                        22000
                                                    </a>
                                                    <div className="dropdown-menu">

                                                        <NavLink to="/twenty-trains">
                                                            Trenler
                                                        </NavLink>

                                                        <NavLink to="/twenty-monthly">
                                                            Aylık
                                                        </NavLink>

                                                        <NavLink to="/twenty-sixmonth">
                                                            6 Aylık
                                                        </NavLink>

                                                        <NavLink to="/two-years">
                                                            2 Yıllık
                                                        </NavLink>

                                                        <NavLink to="/twenty-monthly-alldatas">
                                                            22000 Aylık Tüm Veriler
                                                        </NavLink>

                                                        <NavLink to="/two-years-alldatas">
                                                            22000 2 Yıllık Tüm Veriler
                                                        </NavLink>

                                                    </div>
                                                </div>
                                                : <div className="dropdown dropright">
                                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                        22000
                                                    </a>
                                                    <div className="dropdown-menu">

                                                        <NavLink to="/twenty-monthly-alldatas">
                                                            22000 Aylık Tüm Veriler
                                                        </NavLink>

                                                        <NavLink to="/twenty-sixmonth-alldatas">
                                                            22000 6 Aylık Tüm Veriler
                                                        </NavLink>

                                                        <NavLink to="/two-years-alldatas">
                                                            22000 2 Yıllık Tüm Veriler
                                                        </NavLink>

                                                    </div>
                                                </div>
                                        }

                                        <NavLink to="/login" >
                                            Çıkış Yap
                                        </NavLink>

                                    </div>
                                </div> */}

                                <div>
                                    <NavLink to="/" className="ml-2" >
                                        <span className="prtl-span">
                                            TMS ETCS
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div>
                        <Switch>
                            <Route exact path="/bilgilerim" component={Bilgilerim} />
                            <Route exact path="/ChangePassword" component={ChangePassword} />
                            <Route exact path="/users" component={UsersList} />
                            <Route exact path="/user/add" component={AddUser} />
                            <Route exact path={"/user/edit/" + this.props.guidId} component={UpdateUser} />
                            <Route exact path="/twenty-trains" component={TrainList} />
                            <Route exact path="/twenty-trains/add" component={AddTrain} />
                            <Route exact path={"/twenty-trains/edit/" + this.props.trainId} component={UpdateTrain} />
                            <Route exact path="/twenty-monthly" component={TwentyMonthlyList} />
                            <Route exact path={"/twenty-monthly/edit/" + this.props.twentyMonthlyId} component={TwentyMUpdate} />
                            <Route exact path="/twenty-monthly/add" component={TwentyMonthlyAdd} />
                            <Route exact path={"/twenty-monthly/detail/" + this.props.twentyMonthlyId} component={TwentyMDetail} />
                            <Route exact path="/twenty-monthly-alldatas" component={MonthlyAllDatas} />
                            <Route exact path="/twenty-sixmonth" component={TwentySixMonthList} />
                            <Route exact path="/twenty-sixmonth/add" component={TwentySixMonthAdd} />
                            <Route exact path={"/twenty-sixmonth/detail/" + this.props.twentySixId} component={TwentySDetail} />
                            <Route exact path={"/twenty-sixmonth/edit/" + this.props.twentySixId} component={TwentySUpdate} />
                            <Route exact path="/twenty-sixmonth-alldatas" component={SixMonthAllDatas} />
                            <Route exact path={"/twenty-monthly-alldatas/detail/" + this.props.twentymonthlyallid} component={MonthlyAllDataDetail} />
                            <Route exact path={"/twenty-sixmonth-alldatas/detail/" + this.props.twentysixmonthallid} component={SixMonthAllDataDetail} />
                            {/* 2 Years Routers */}
                            <Route exact path="/two-years" component={TwoYearsList} />
                            <Route exact path="/two-years/add" component={TwoYearsAdd} />
                            <Route exact path={"/two-years/detail/" + this.props.twentyTwoYearsId} component={TwoYearsDetail} />
                            <Route exact path={"/twoyears/edit/" + this.props.twentyTwoYearsId} component={TwoYearsUpdate} />
                            <Route exact path="/two-years-alldatas" component={TwoYearsAllDatas} />
                            <Route exact path={"/two-years-alldatas/detail/" + this.props.twentytwoyearsallid} component={TwoYearsAllDataDetail} />
                        </Switch>
                    </div>
                    <FooterBack />
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    guidId: selectGuidId(),
    trainId: selectTrainId(),
    twentyMonthlyId: selectTwentyMonthlyId(),
    twentySixId: selectTwentySixMonthId(),
    twentyTwoYearsId: selectTwentyTwoYearsId(),
    user: selectUser(),
    twentymonthlyallid: selectTwentyMonthlyAllId(),
    twentysixmonthallid: selectTwentySixMonthAllId(),
    twentytwoyearsallid: selectTwentyTwoYearsAllId(),
})

export default connect(mapStateToProps)(BackPage)
