export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER = 'SET_USER'
export const SET_TWENTYMONTHLY = 'SET_TWENTYMONTHLY'
export const SET_TWENTYSIXMONTH = 'SET_TWENTYSIXMONTH'
export const SET_TWENTYTWOYEARS = 'SET_TWENTYTWOYEARS'
export const SET_GUIDID = 'SET_GUIDID'
export const SET_TRAINS = 'SET_TRAINS'
export const ADD_TRAINS = 'ADD_TRAINS'
export const SET_TRAINID = 'SET_TRAINID'
export const SET_TWENTYMID = 'SET_TWENTYMID'
export const SET_TWENTYSIXMID = 'SET_TWENTYSIXMID'
export const SET_TWENTY_TWO_YEARS_MID = 'SET_TWENTY_TWO_YEARS_MID'
export const SET_STATE = 'SET_STATE'
export const RESET_STATE = 'RESET_STATE'
export const SET_TWENTYMALLID = 'SET_TWENTYMALLID'
export const SET_TWENTYSIXALLID = 'SET_TWENTYSIXALLID'
export const SET_TWENTY_TWO_YEARS_ALL_ID = 'SET_TWENTY_TWO_YEARS_ALL_ID'