import React, { Component } from 'react'
import Axios from 'axios'
import { createStructuredSelector } from 'reselect';
import { selectGuidId, selectToken } from '../../../store/selectors';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
let ProfilImage = false;

class UpdateUser extends Component {
    state = {
        "Name": "",
        "Surname": "",
        "Authority": "",
        "Email": "",
        "Phone": "",
        "Address": "",
        "Image": "",
        "UserName": "",
    }

    componentDidMount = async () => {
        await Axios.get(`https://tmstechnic.pythonanywhere.com/loko/users/detail/${this.props.guidId}`, {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(res => {
                this.setState({
                    Name: res.data.Name,
                    Surname: res.data.Surname,
                    Authority: res.data.Authority,
                    Email: res.data.Email,
                    Phone: res.data.Phone,
                    Address: res.data.Address,
                    UserName: res.data.UserName,
                })

            })
    }

    handleChangeImage = (e) => {
        this.setState({
            Image: e.target.files[0]
        })
        ProfilImage = true
    }

    changeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeAuthority = (e) => {
        this.setState({
            Authority: e.target.value
        })
    }

    onClickSave = async (e) => {
        e.preventDefault();
        if (this.state.Email === "") {
            alert("Lütfen Email kısmını boş geçmeyiniz!!!")
        }
        else {
            let form_data = new FormData();
            ProfilImage ? form_data.append('Image', this.state.Image, this.state.Image.name) : <div></div>;
            form_data.append('Name', this.state.Name);
            form_data.append('Surname', this.state.Surname);
            form_data.append('Authority', this.state.Authority);
            form_data.append('Email', this.state.Email);
            form_data.append('Phone', this.state.Phone);
            form_data.append('Address', this.state.Address);
            form_data.append('UserName', this.state.UserName);

            await Axios.put(`https://tmstechnic.pythonanywhere.com/loko/users/update/${this.props.guidId}`, form_data, {
                headers: {
                    Authorization: `JWT ${this.props.token}`
                }
            })
                .then(() => {
                    window.location.replace("http://localhost:3000/users")
                })
        }
    }

    render() {
        const { Name, Surname, Authority, UserName, PersonelNo, Email, Phone, Address } = this.state
        return (
            <div>
                <div className="page-name-part">
                    <Link to="/users" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>
                    <h2>
                        Kullanıcı Düzenle
                    </h2>
                </div>

                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            Kullanıcı Düzenle
                        </div>
                    </div>
                    <div className="padding-div-back">
                        <div className="form-group row">
                            <label for="inputEmail1" className="col-sm-2 col-form-label ">
                                Resim Seçiniz:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="file" className="form-control pt-1" id="ProfilImage"
                                    accept="image/png, image/jpeg" onChange={this.handleChangeImage} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail2" className="col-sm-2 col-form-label ">
                                Kullanıcı Adı:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control" id="UserName" value={UserName} name="UserName" disabled />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Personel No:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="PersonelNo" value={PersonelNo} name="PersonelNo" disabled />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                İsim:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Name" onChange={this.changeInput} value={Name} name="Name"
                                    maxLength="15" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Soyisim:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Surname" onChange={this.changeInput} value={Surname} name="Surname" maxLength="25" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="cinsiyet" className="col-sm-2 col-form-label">
                                Yetki:
                            </label>
                            <div className="col-sm-6" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="Authority" onChange={this.onChangeAuthority} value={Authority}
                                    aria-label="Default select example">
                                    <option value="">Belirtilmemiş</option>
                                    <option value="Manager">Süper Yönetici</option>
                                    <option value="User">Kullanıcı</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Email:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Email" onChange={this.changeInput} value={Email} name="Email" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Telefon:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Phone" onChange={this.changeInput} value={Phone} name="Phone" maxlength="11"
                                    minLength="11"
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="inputEmail3" className="col-sm-2 col-form-label" >
                                Adres:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Address" onChange={this.changeInput} value={Address} name="Address"
                                    maxLength="100" />
                            </div>
                        </div>


                        <div className=" row text-right mt-4">
                            <div className="col-sm-8">
                                <button className="btn right-part-button" onClick={this.onClickSave}>
                                    Kaydet
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token: selectToken(),
    guidId: selectGuidId(),
})

export default connect(mapStateToProps)(UpdateUser)