import Axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectToken, selectUser } from '../../store/selectors';
import { setUser } from '../../store/actions'
import { Link } from 'react-router-dom'
let ProfilImage = false;

class Bilgilerim extends Component {
    state = {
        "Name":"",
        "Surname":"",
        "GuidId":null,
        "Authority":"",
        "Email":"",
        "Phone":"",
        "Address":"",
        "Image":"",
        "UserName":"",
        "PersonelNo":null,
        "ProfilImage":null,
    }

    componentDidMount = async() => {
        this.setState({
            Name:this.props.user.Name,
            Surname:this.props.user.Surname,
            GuidId:this.props.user.GuidId,
            Authority:this.props.user.Authority,
            Email:this.props.user.Email,
            Phone:this.props.user.Phone,
            Address:this.props.user.Address,
            UserName:this.props.user.UserName,
            PersonelNo:this.props.user.PersonelNo,
        })
    }

    handleChangeImage = (e) => {
        this.setState({
            Image:e.target.files[0]
        })

        ProfilImage = true;
    }

    changeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onChangeAuthority = (e) => {
        this.setState({
            Authority:e.target.value
        })
    }

    onClickSave = async(e) => {
        e.preventDefault();
        if(this.state.Email === ""){
            alert("Mail adresini doldurunuz.")
        }
        else{
            let form_data = new FormData();
            ProfilImage ? form_data.append('Image', this.state.Image, this.state.Image.name):<div></div>;
            form_data.append('UserName', this.state.UserName);
            form_data.append('Name', this.state.Name);
            form_data.append('Surname', this.state.Surname);
            form_data.append('Authority', this.state.Authority);
            form_data.append('Email', this.state.Email);
            form_data.append('Phone', this.state.Phone);
            form_data.append('Address', this.state.Address);
    
            await Axios.put(`https://tmstechnic.pythonanywhere.com/loko/users/update/${this.state.GuidId}`,form_data, {
                headers: {
                    Authorization: `JWT ${this.props.token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then(response => {
                this.props.setUser(response.data)
                window.location.reload()
            })
        } 
    }

    render() {
        const {Name,Surname,Authority,Email,Phone,Address,UserName,PersonelNo} = this.state
        return (
            <div>
                <div className="page-name-part">
                    <Link to="/" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>
                    <h2>
                            Genel Bilgiler       
                    </h2>
                </div>

                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            Bilgilerim
                        </div>
                    </div>
                    <div className="padding-div-back" >
                        <div className="form-group row">
                            <label for="ProfilImage" className="col-sm-2 col-form-label">
                                Resim Seçiniz:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="file" className="form-control pt-1" id="ProfilImage"
                                    accept="image/png, image/jpeg" 
                                    onChange={this.handleChangeImage}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="UserName" className="col-sm-2 col-form-label">
                                Kullanıcı Adı:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control" id="UserName" name="UserName" disabled value={UserName}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="PersonelNo" className="col-sm-2 col-form-label" >
                                Personel No:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="PersonelNo" name="PersonelNo" disabled value={PersonelNo}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="Name" className="col-sm-2 col-form-label" >
                                İsim:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Name" name="Name" placeholder="İsminiz" 
                                value={Name} onChange={this.changeInput} maxLength="15"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="Surname" className="col-sm-2 col-form-label" >
                                Soyisim:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Surname" name="Surname" placeholder="Soyisminiz"
                                value={Surname} onChange={this.changeInput} maxLength="20"/>
                            </div>
                        </div>
                        {
                            this.props.user.Authority==="Manager"?<div className="form-group row">
                            <label for="Authority" className="col-sm-2 col-form-label">
                                Yetki:
                        </label>
                            <div className="col-sm-6" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="Authority" 
                                value={Authority} onChange={this.onChangeAuthority}
                                    aria-label="Default select example">                              
                                    <option value="Manager">Süper Yönetici</option>
                                    <option value="User">Kullanıcı</option>
                                </select>
                            </div>
                        </div>:null
                        }


                        <div className="form-group row">
                            <label for="Email" className="col-sm-2 col-form-label" >
                                Email:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Email" name="Email" placeholder="Email"
                                value={Email} onChange={this.changeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="Phone" className="col-sm-2 col-form-label" >
                                Telefon:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Phone" name="Phone" placeholder="Telefon" maxlength="11" minLength="11"
                                value={Phone} onChange={this.changeInput} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="Address" className="col-sm-2 col-form-label" >
                                Adres:
                            </label>
                            <div className="col-sm-6 mt-1">
                                <input type="text" className="form-control pt-1" id="Address" name="Address" placeholder="Adres"
                                value={Address} maxLength="100"/>
                            </div>
                        </div>

                         
                        <div className=" row text-right mt-4">
                            <div className="col-sm-8">
                                <button className="btn right-part-button" onClick={this.onClickSave}>
                                    Kaydet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    user:selectUser(),
    token:selectToken(),
})

const mapDispatchToProps = (dispatch) => (
    (
        bindActionCreators({
            setUser,
        },dispatch)
    )
)

export default connect(mapStateToProps,mapDispatchToProps)(Bilgilerim)