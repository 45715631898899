import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectTwentyMonthly } from '../../../../../store/selectors'
import Calculate from './Calculate'

function UpcomingMonthlyServices(props) {
    return (
        <div className="parts-body">
            <div className="parts-body-header">
                <div className="w-75 link">
                    Yaklaşan Aylık Bakımlar
                </div>
            </div>
            <div className="parts-body-upcoming">
                <table className="table table-striped" >
                    <thead key="thead" className="thead-upcoming">
                        <tr>
                            <th scope="col">Tren Numarası</th>
                            <th scope="col">Bakım Zamanına Kalan Gün Sayısı</th>
                        </tr>
                    </thead>
                    <tbody key="tbody" className="tbody-upcoming">
                        {
                            props.twentytrains.map(tt => {
                                return (
                                        <Calculate nextMaintenance={tt.NextMaintenance} trainNumber={tt.TrainName} />                           
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentytrains: selectTwentyMonthly()
})

export default connect(mapStateToProps)(UpcomingMonthlyServices)