import React, { Component } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { selectToken } from '../../../../store/selectors'
import { setTwentyTwoYears, setTwentyTwoYearsId } from '../../../../store/actions'
import Axios from 'axios';

class TwoYearsList extends Component {
    state = {
        "twentyTwoYearsTrainList": [],
    };

    componentDidMount = async () => {
        await Axios.get("https://tmstechnic.pythonanywhere.com/loko/twoyears/list", {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        })
            .then(res => {
                this.setState({
                    twentyTwoYearsTrainList: res.data
                })
                this.props.setTwentyTwoYears(res.data)
            })

        $(document).ready(function () {

            $('#example1').DataTable({
                "language": {
                    "lengthMenu": "Sayfada _MENU_ Kayıt Göster",
                    "zeroRecords": "Aradığınızla eşleşen bir kayıt bulunamadı",
                    "info": "_TOTAL_ Kayıttan _START_ ile _END_ Arası Kayıtlar",
                    "infoEmpty": "Hiç Kayıt Bulunamadı",
                    "infoFiltered": "( _MAX_ Kayıt Arasından )",
                    "search": "Bul:",
                },
                "order": [[0, "asc"]]
            });
        });
    }

    onClickDetail = async(id) => {
        await this.props.setTwentyTwoYearsId(id)
        window.location.replace("/two-years/detail/"+ id)
    }

    onClickEdit = async(id) => {
        await this.props.setTwentyTwoYearsId(id)
        window.location.replace("/twoyears/edit/"+id)
    }

    onClickDelete = async(id) => {
        await Axios.delete(`https://tmstechnic.pythonanywhere.com/loko/twoyears/delete/${id}`,{
            headers:{
                Authorization:`JWT ${this.props.token}`
            }
        })
        .then(()=>{
            window.location.reload()
        })
    }

    render() {
        return (
            <div>
                <div className="page-name-part">
                    <Link to="/bilgilerim" className="link">
                            <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>
                    <h2>
                        22000 - 2 Yıllık Bakım
                    </h2>
                </div>
                <div className="back-body border-0 text-left ">
                    <Link to="/two-years/add" className="btn success-button">Yeni Tren Verisi Ekle</Link>
                </div>
                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            22000 2 Yıllık Bakım Listesi
                        </div>
                    </div>
                    <div className="padding-div-back">
                        <table id="example1" className="display">
                            <thead>
                                <tr>
                                    <th>Tren Numarası</th>
                                    <th>Son Yapılan Bakım</th>
                                    <th>Gelecek Bakım</th>
                                    <th>İşlemler</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.twentyTwoYearsTrainList.map(tmt => {

                                        return (
                                            <tr>
                                                <td>{tmt.TrainName}</td>
                                                <td>{tmt.LastMaintenance.split("-").reverse().join("-")}</td>
                                                <td>{tmt.NextMaintenance.split("-").reverse().join("-")}</td>
                                                <td>
                                                    <div>
                                                        <button className="btn btn-sm detail-button" onClick={this.onClickDetail.bind(this,tmt.GuidId)}>
                                                            Detay
                                                        </button>
                                                        <button className="btn btn-sm edit-button" onClick={this.onClickEdit.bind(this,tmt.GuidId)}>
                                                            Düzenle
                                                        </button>
                                                        <button className="btn btn-sm delete-button" onClick={this.onClickDelete.bind(this,tmt.GuidId)}>
                                                            Sil
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token: selectToken(),
});

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTwentyTwoYears, setTwentyTwoYearsId
        }, dispatch)
    )
)
export default connect(mapStateToProps, mapDispatchToProps)(TwoYearsList)