import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTwentyMonthly } from '../../../../../store/selectors';

function OneMonthBody(props) {

    return (
        <div className="parts-body">
            <div className="parts-body-header">
                <div className="w-75 link">
                    Aylık Bakım
                </div>
                <div className="w-25 text-right link">
                    <button type="button" className="btn btn-primary px-2 py-1" data-toggle="modal" data-target="#openMonthly">
                        Detaylar
                    </button>
                </div>
            </div>
            <div className="parts-body-parts">
                <table className="table table-striped" >
                    <thead className="thead">
                        <tr>
                            <th scope="col">Tren Numarası</th>
                            <th scope="col">Son Bakım Yapılan</th>
                            <th scope="col">Gelecek Bakım</th>
                            <th scope="col">Durum</th>
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {
                            props.twentymonthly.map(tm => {
                            return(
                            <tr>
                                <th scope="row">{tm.TrainName}</th>
                                <td>{tm.LastMaintenance.split("-").reverse().join("-")}</td>
                                <td>{tm.NextMaintenance.split("-").reverse().join("-")}</td>
                                <td>{tm.Status}</td>
                            </tr>
                            )
                            })
                        }
                       
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentymonthly:selectTwentyMonthly()
})

export default connect(mapStateToProps)(OneMonthBody)