import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {  selectTwentySixMonth } from '../../../../../store/selectors'
import SixMonthCalculate from './SixMonthCalculate'

function SixMonthUpcoming(props) {
    return (
        <div className="parts-body">
            <div className="parts-body-header">
                <div className="w-75 link">
                    Yaklaşan 6 Aylık Bakımlar
                </div>
            </div>
            <div className="parts-body-upcoming">
                <table className="table table-striped" >
                    <thead className="thead-upcoming">
                        <tr>
                            <th scope="col">Tren Numarası</th>
                            <th scope="col">Bakım Zamanına Kalan Gün Sayısı</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-upcoming">
                        {
                            props.twentysixmonth.map(tsm => {
                                return (
                                    <SixMonthCalculate nextMaintenance={tsm.NextMaintenance} trainNumber={tsm.TrainName} />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentysixmonth: selectTwentySixMonth(),
})

export default connect(mapStateToProps)(SixMonthUpcoming)