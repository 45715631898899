import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTwentyTwoYears } from '../../../../../store/selectors';
import TwoYearsCalculate from './TwoYearsCalculate';

function TwoYearsUpcoming(props) {
    return (
        <div className="parts-body">
            <div className="parts-body-header">
                <div className="w-75 link">
                    Yaklaşan 2 Yıllık Bakımlar
                </div>
            </div>
            <div className="parts-body-upcoming">
                <table className="table table-striped" >
                    <thead className="thead-upcoming">
                        <tr>
                            <th scope="col">Tren Numarası</th>
                            <th scope="col">Bakım Zamanına Kalan Gün Sayısı</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-upcoming">
                        {
                            props.twentyTwoYearsList.map(tsm => {
                                return (
                                    <TwoYearsCalculate nextMaintenance={tsm.NextMaintenance} trainNumber={tsm.TrainName} />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentyTwoYearsList: selectTwentyTwoYears()
})

export default connect(mapStateToProps)(TwoYearsUpcoming)