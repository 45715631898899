import React from 'react'

function Footer() {
    return (
        <div className="footer-homepage">
            <marquee direction="right">
           <i> Copyright © 2021 TMS, Tüm Hakları Saklıdır. Made by TMS AR-GE. </i>
            </marquee>
        </div>
    )
}
export default Footer
