import React, { Component } from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Operations from './Operations'
import SixtyEight from './TwentyTwo/TwentyTwoSixMonth'
import TwentyTwo from './TwentyTwo/TwentyTwoMonthly'
import TwoYearsMaintenance from './TwentyTwo/TwoYearsMaintenance'

class HomePage extends Component {

    render() {
        return (
            <div>
                <Operations/>
                <Navbar/>
                <div className="d-flex m-auto home-page" style={{width:"95%"}}> 
                    <TwentyTwo/>
                    <SixtyEight/>
                    <TwoYearsMaintenance />
                </div>
               <Footer/>
            </div>
        )
    }
}

export default (HomePage)