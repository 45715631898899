import React, { Component } from 'react'
import Axios from 'axios'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import { selectToken } from '../../../../store/selectors'
import { setTrains,addTrains } from '../../../../store/actions'
import { Link } from 'react-router-dom'
class AddTrain extends Component {
    state={
        "TrainName":"",
        "Status":"",
        "GuidId":null,
    }

    onChangeTrainName = (e) => {
        this.setState({
            TrainName:e.target.value
        })
    }

    onChangeStatus = (e) => {
        this.setState({
            Status:e.target.value
        })
    }

    onClickSave = async() => {
        if(this.state.Status ==="" || this.state.TrainName===""){
            alert("Lütfen Bilgileri Eksiksiz Giriniz")
        }
        else{
            let randm=Math.floor(Math.random() * 1000000)
        await this.setState({
            GuidId:randm
        })

        await Axios.post(`https://tmstechnic.pythonanywhere.com/loko/train/create`,this.state,{
            headers:{
                Authorization: `JWT ${this.props.token}`
            }
        }).then(async()=>{
            await this.props.addTrains(this.state)
            this.setState({
                TrainName:"",
                Status:"",
                GuidId:null,
            })
        })
        .catch(error => alert("Böyle Bir Tren Zaten Bulunmaktadır."))
        }    
    }
    render() {
        return (
            <div>
            <div className="page-name-part">

                <Link to="/twenty-trains" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                </Link>

                <h2>
                        Tren Ekle     
                </h2>

            </div>

            <div className="back-body">
                <div className="back-body-header">
                    <div className="w-75 link" >
                        Tren Ekle
                    </div>
                </div>
                <div className="padding-div-back">

                    <div className="form-group row">
                        <label for="inputEmail2" className="col-sm-2 col-form-label ">
                            Tren Adı:
                        </label>
                        <div className="col-sm-6 mt-1">
                            <input type="text" className="form-control" id="TrainName" name="TrainName" onChange={this.onChangeTrainName} placeholder="Tren Adını Giriniz"
                            value={this.state.TrainName} maxLength="8"/>
                        </div>
                    </div>

                    <div className="form-group row">
                            <label for="cinsiyet" className="col-sm-2 col-form-label  ">
                                Durumu:
                            </label>
                            <div className="col-sm-6" >
                                <select className="col-sm-12 form-select p-2" id="Authority" name="Authority" onChange={this.onChangeStatus}
                                value={this.state.Status} defaultValue="Faal">
                                    <option value="">Seçiniz</option>
                                    <option value="Faal">Faal</option>
                                    <option value="GayriFaal">Gayri-Faal</option>
                                </select>
                            </div>
                        </div>
                     
                    <div className=" row text-right mt-4">
                        <div className="col-sm-8">
                            <button className="btn right-part-button" onClick={this.onClickSave}>
                                Kaydet
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token:selectToken(),
})

const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTrains,addTrains
        },dispatch)
    )
)
export default connect(mapStateToProps,mapDispatchToProps)(AddTrain)