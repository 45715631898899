import React from 'react'

function Calculate(props) {

    var someDate = new Date();
    let date = someDate.getFullYear() + "-" + (someDate.getMonth() + 1) + "-" + someDate.getDate()
    var tarih1 = new Date(date);
    var tarih2 = new Date(props.nextMaintenance);
    var zamanFark = tarih2.getTime() - tarih1.getTime();
    var gunFark = Math.ceil(zamanFark / (1000 * 3600 * 24));

    let k;

    if (gunFark >= 7 && gunFark <= 10) {
        k = <>
            <th scope="row" className="py-3" >{props.trainNumber}</th>
            <td>
                <div className="div-yellow">
                    {gunFark - 1} gün kaldı
                </div>
            </td>
        </>
    }
    else if (gunFark < 7 && gunFark >= 1) {
        k = <>
            <th scope="row" className="py-3" >{props.trainNumber}</th>
            <th>
                <div className="div-red">
                    {gunFark - 1} gün kaldı
                </div>
            </th>
        </>
    }

    else if (gunFark < 1) {
        k = <>
            <th scope="row" className="py-3">{props.trainNumber}</th>
            <th>
                <div className="div-red">
                    {Math.abs(gunFark - 1)} gün geçti
                </div>
            </th>
        </>
    }
    else {
        return null
    }
    return (
        <tr key={props.trainNumber}>
            {k}
        </tr>
    )
}

export default Calculate