import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTwentySixMonth } from '../../../../../store/selectors';

function SixMonthsBody(props) {
    return (
        <div className="parts-body">
            <div className="parts-body-header">
                <div className="w-75 link">
                    6 Aylık Bakım
                </div>
                <div className="w-25 text-right link">
                    <button type="button" className="btn btn-primary px-2 py-1" data-toggle="modal" data-target="#openSixMonthly">
                        Detaylar
                    </button>
                </div>
            </div>
            <div className="parts-body-parts">
                <table className="table table-striped">
                    <thead className="thead">
                        <tr>
                            <th scope="col">Tren Numarası</th>
                            <th scope="col">Son Bakım Yapılan</th>
                            <th scope="col">Gelecek Bakım</th>
                            <th scope="col">Durum</th>
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {
                            props.twentysixmonth.map(tsm => {
                                return (
                                    <tr>
                                        <th scope="row">{tsm.TrainName}</th>
                                        <td>{tsm.LastMaintenance.split("-").reverse().join("-")}</td>
                                        <td>{tsm.NextMaintenance.split("-").reverse().join("-")}</td>
                                        <td>{tsm.Status}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentysixmonth: selectTwentySixMonth(),
})

export default connect(mapStateToProps)(SixMonthsBody);