import React, { useEffect } from 'react'

export default function NotFoundTwo() {
    useEffect(() => {
        window.location.replace("/")
    }, [])
    return (
        <div>
            Not Found
        </div>
    )
}
