import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './Components/HomePage/HomePage'
import './App.css'
import Login from './Components/Login'
import PasswordRemember from './Components/PasswordRemember'
import BackPage from './Components/Back/BackPage'
import NotFound from './Components/NotFound'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectGuidId, selectTrainId, selectTwentyMonthlyId, selectTwentySixMonthId, selectDurum, selectUser, selectTwentyMonthlyAllId, selectTwentySixMonthAllId, selectToken, selectTwentyTwoYearsId, selectTwentyTwoYearsAllId } from './store/selectors'
import NotFoundTwo from './Components/NotFoundTwo'
import { bindActionCreators } from 'redux'
import { setGuidId, setTrainId, setTwentyMonthlyId, setTwentySixMonthId, setTwentyTwoYearsId, setTwentyMonthlyAllId, setTwentySixMonthAllId, setToken } from './store/actions'
import { Component } from 'react'


class App extends Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname
    let newpath = path.split("/").reverse()
    this.props.setTrainId(newpath[0])
    this.props.setGuidId(newpath[0])
    this.props.setTwentyMonthlyId(newpath[0])
    this.props.setTwentySixMonthId(newpath[0])
    this.props.setTwentyTwoYearsId(newpath[0])
    this.props.setTwentyMonthlyAllId(newpath[0])
    this.props.setTwentySixMonthAllId(newpath[0])
  }

  componentDidMount = async () => {
    if (this.props.token[0] !== "Not a valid string." && this.props.token[0] !== "This field may not be blank.") {
      let LogIn = {
        token: this.props.token
      };

      const options = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(LogIn),
      };

      await fetch("https://tmstechnic.pythonanywhere.com/api-token-refresh/", options)
        .then((r) => r.json())
        .then((data) => {
          if (
            data.non_field_errors === "Unable to log in with provided credentials."
          ) {
            alert(data.non_field_errors[0]);
          } else {

          }
        })
    }


  }
  render() {

    return (
      <Router>
        {
          this.props.durum ? this.props.user.Authority === "Manager" ? <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/passwordremember" component={PasswordRemember} />
            <Route exact path="/bilgilerim" component={BackPage} />
            <Route exact path="/ChangePassword" component={BackPage} />
            <Route exact path="/users" component={BackPage} />
            <Route exact path="/user/add" component={BackPage} />
            <Route exact path={"/user/edit/" + this.props.guidId} component={BackPage} />
            <Route exact path="/twenty-trains" component={BackPage} />
            <Route exact path="/twenty-trains/add" component={BackPage} />
            <Route exact path={"/twenty-trains/edit/" + this.props.trainId} component={BackPage} />
            <Route exact path="/twenty-monthly" component={BackPage} />
            <Route exact path={"/twenty-monthly/edit/" + this.props.twentyMonthlyId} component={BackPage} />
            <Route exact path="/twenty-monthly/add" component={BackPage} />
            <Route exact path={"/twenty-monthly/detail/" + this.props.twentyMonthlyId} component={BackPage} />
            <Route exact path="/twenty-monthly-alldatas" component={BackPage} />
            <Route exact path={"/twenty-monthly-alldatas/detail/" + this.props.twentymonthlyallid} component={BackPage} />

            <Route exact path="/twenty-sixmonth" component={BackPage} />
            <Route exact path="/twenty-sixmonth/add" component={BackPage} />
            <Route exact path={"/twenty-sixmonth/detail/" + this.props.twentysixid} component={BackPage} />
            <Route exact path={"/twenty-sixmonth/edit/" + this.props.twentysixid} component={BackPage} />
            <Route exact path="/twenty-sixmonth-alldatas" component={BackPage} />
            <Route exact path={"/twenty-sixmonth-alldatas/detail/" + this.props.twentysixmonthallid} component={BackPage} />
            
            {/* Two Years Routers */}
            <Route exact path="/two-years" component={BackPage} />
            <Route exact path="/two-years/add" component={BackPage} />
            <Route exact path={"/two-years/detail/" + this.props.twentyTwoYearsId} component={BackPage} />
            <Route exact path={"/twoyears/edit/" + this.props.twentyTwoYearsId} component={BackPage} />
            <Route exact path="/two-years-alldatas" component={BackPage} />
            <Route exact path={"/two-years-alldatas/detail/" + this.props.twentytwoyearsallid} component={BackPage} />
            <Route exact component={NotFoundTwo} />
          </Switch> : <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/passwordremember" component={PasswordRemember} />
            <Route exact path="/bilgilerim" component={BackPage} />
            <Route exact path="/ChangePassword" component={BackPage} />
            <Route exact path="/twenty-monthly-alldatas" component={BackPage} />
            <Route exact path={"/twenty-monthly-alldatas/detail/" + this.props.twentymonthlyallid} component={BackPage} />
            <Route exact path="/twenty-sixmonth-alldatas" component={BackPage} />
            <Route exact path={"/twenty-sixmonth-alldatas/detail/" + this.props.twentysixmonthallid} component={BackPage} />
            {/* Two Years */}
            <Route exact path="/two-years-alldatas" component={BackPage} />
            <Route exact path={"/two-years-alldatas/detail/" + this.props.twentytwoyearsallid} component={BackPage} />
            <Route exact component={NotFoundTwo} />
          </Switch>
            :
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/passwordremember" component={PasswordRemember} />
              <Route exact component={NotFound} />
            </Switch>
        }

      </Router >
    );

  }
}


const mapStateToProps = createStructuredSelector({
  guidId: selectGuidId(),
  trainId: selectTrainId(),
  twentyMonthlyId: selectTwentyMonthlyId(),
  twentysixid: selectTwentySixMonthId(),
  twentyTwoYearsId: selectTwentyTwoYearsId(),
  durum: selectDurum(),
  user: selectUser(),
  twentymonthlyallid: selectTwentyMonthlyAllId(),
  twentysixmonthallid: selectTwentySixMonthAllId(),
  twentytwoyearsallid: selectTwentyTwoYearsAllId(),
  token: selectToken(),
})

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({
      setGuidId, setTrainId, setTwentyMonthlyId, setTwentySixMonthId, setTwentyTwoYearsId, setTwentyMonthlyAllId, setTwentySixMonthAllId, setToken
    }, dispatch)
  )
)
export default connect(mapStateToProps, mapDispatchToProps)(App);
