import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTwentyTwoYears } from '../../../../store/selectors';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        marginTop: "10px",
        marginBottom: "10px"
    },
    heading: {
        fontSize: "16px",
        flexShrink: 0,
    },
}));

function TwoYearsPopup(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="modal fade" id="openTwoYears" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content m-0" style={{ paddingTop: "20px" }}>
                    <div className="modal-header mx-auto modal-header-part">
                        <span className="modal-header-part-span">
                            <i className="fa fa-subway mr-1 ml-0" style={{ fontSize: "20px" }} />
                            2 Yıllık Bakım 22000
                        </span>

                        <button type="button" className="close x-button" data-dismiss="modal" style={{ fontSize: "24px" }}>
                            ×
                        </button>
                    </div>
                    <div className="modal-body p-0" style={{ width: "95%", margin: "auto" }}>
                        <div className="popup-main-div">
                            {/* <Date /> */}
                            <div className={classes.root}>
                                {props.twentyTwoYearsList.map(tm => (
                                    <Accordion expanded={expanded === tm.TrainName} onChange={handleChange(tm.TrainName)} key={tm.TrainName}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={tm.TrainName}
                                            id={tm.TrainName}
                                        >
                                            <Typography component={'span'} className={classes.heading}>
                                            {tm.TrainName}
                                            </Typography>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography component={'span'}>
                                                <div className="p-3">
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <b>Aylık Bakım Tarihi : </b> {tm.LastMaintenance.split("-").reverse().join("-")}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <b>Sonra ki Bakım Tarihi :</b> {tm.NextMaintenance.split("-").reverse().join("-")}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <b>Durumu :</b> {tm.Status}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-5">
                                                            <b>Bakımda Ölçülen Teker Çapları :</b>
                                                        </div>
                                                        <div className="col-7">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr style={{ backgroundColor: "#ec7424", color: "white" }}>
                                                                        <th scope="col">AKS</th>
                                                                        <th scope="col">Sol Teker</th>
                                                                        <th scope="col">Sağ Teker</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="tb">
                                                                    <tr>
                                                                        <th scope="row">1</th>
                                                                        <td>{tm.FirstAKSLeft}</td>
                                                                        <td>{tm.FirstAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">2</th>
                                                                        <td>{tm.SecondAKSLeft}</td>
                                                                        <td>{tm.SecondAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">3</th>
                                                                        <td>{tm.ThirdAKSLeft}</td>
                                                                        <td>{tm.ThirdAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">4</th>
                                                                        <td>{tm.FourthAKSLeft}</td>
                                                                        <td>{tm.FourthAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">5</th>
                                                                        <td>{tm.FifthAKSLeft}</td>
                                                                        <td>{tm.FifthAKSRight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">6</th>
                                                                        <td>{tm.SixthAKSLeft}</td>
                                                                        <td>{tm.SixthAKSRight}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col">
                                                            <b>Doppler Kalibrasyonu :</b> {tm.Doppler.split("-").reverse().join("-")}
                                                        </div>
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <b> Açıklama :</b>
                                                        </div>
                                                        <div className="col-9" style={{
                                                            wordWrap: "break-word"
                                                        }}>
                                                            <p>
                                                            {tm.Content}
                                                            </p>
                                                            <img src={tm.Image} alt={tm.Image} style={{ width: "50%", objectFit: "scale-down" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">
                            Kapat
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    twentyTwoYearsList: selectTwentyTwoYears()
});

export default connect(mapStateToProps)(TwoYearsPopup);