import React, { Component } from 'react'
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectToken, selectTrains, selectUser } from '../../../../store/selectors';
import { setTwentyMonthlyAllId } from '../../../../store/actions'
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'

class MonthlyAllDatas extends Component {
    state = {
        "alldatas": []
    }
    componentDidMount = async () => {

        await axios.get("https://tmstechnic.pythonanywhere.com/loko/twentymonthlyalldatas/list?ordering=-GuidId", {
            headers: {
                Authorization: `JWT ${this.props.token}`
            }
        }).then(res => {
            this.setState({
                alldatas: res.data
            })
        }
        )
        $(document).ready(function () {

            $('#example1').DataTable({
                "language": {
                    "lengthMenu": "Sayfada _MENU_ Kayıt Göster",
                    "zeroRecords": "Aradığınızla eşleşen bir kayıt bulunamadı",
                    "info": "_TOTAL_ Kayıttan _START_ ile _END_ Arası Kayıtlar",
                    "infoEmpty": "Hiç Kayıt Bulunamadı",
                    "infoFiltered": "( _MAX_ Kayıt Arasından )",
                    "search": "Bul:",
                },
                "order": []
            });
        });
    }

    onClickDetail = id => {
        this.props.setTwentyMonthlyAllId(id)
        window.location.replace(`/twenty-monthly-alldatas/detail/${id}`)
    }

    onClickDelete = async(id)=> {
        await axios.delete(`https://tmstechnic.pythonanywhere.com/loko/twentymonthlyalldatas/delete/${id}`,{
            headers:{
                Authorization:`JWT ${this.props.token}`
            }
        })
        .then(()=>{
            window.location.replace(`/twenty-monthly-alldatas`)
        })
    }
    render() {
        return (
            <div>
                <div className="page-name-part">
                    
                    <Link to="/bilgilerim" className="link">
                        <i className="fa fa-arrow-circle-o-left fa-2x"></i>
                    </Link>

                    <h2>
                        22000 - Aylık Tüm Veriler
                    </h2>

                </div>
                <div className="back-body">
                    <div className="back-body-header">
                        <div className="w-75 link">
                            22000 Verilerin Listesi
                        </div>
                    </div>
                    <div className="padding-div-back">
                        <table id="example1" className="display">
                            <thead>
                                <tr>
                                    <th>Tren Numarası</th>
                                    <th>Son Yapılan Bakım</th>
                                    <th>Gelecek Bakım</th>
                                    <th>Durumu</th>
                                    <th>Detay</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.alldatas.map(ad => {
                                        let trainname = this.props.trains.filter(train => train.GuidId === ad.TrainId)
                                        return (
                                            <tr>
                                                <td>{trainname[0].TrainName}</td>
                                                <td>{ad.LastMaintenance.split("-").reverse().join("-")}</td>
                                                <td>{ad.NextMaintenance.split("-").reverse().join("-")}</td>
                                                <td>{ad.Status}</td>
                                                <td>
                                                    <div>
                                                        <button className="btn btn-sm detail-button" onClick={this.onClickDetail.bind(this,ad.GuidId)}>
                                                            Detay
                                                        </button>
                                                        {
                                                            this.props.user.UserName === "mikailsenturk" || this.props.user.UserName === "msedatgul" ? 
                                                            <button className="btn btn-sm delete-button" onClick={this.onClickDelete.bind(this,ad.GuidId)}>
                                                            Sil
                                                        </button>:
                                                        null
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    token: selectToken(),
    trains: selectTrains(),
    user: selectUser(),
})
const mapDispatchToProps = dispatch => (
    (
        bindActionCreators({
            setTwentyMonthlyAllId,
        },dispatch)
    )
)
export default connect(mapStateToProps,mapDispatchToProps)(MonthlyAllDatas)